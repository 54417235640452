import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
//---------------------------------------------------------------------------------
import { ModalService } from '../services/modal/modal.service';
import { CancelOrder } from 'src/app/store/self-service/self-service.action';
import {
  SskButton,
  SskConfirmModal
} from 'src/app/pages/self-service/modals/ssk-confirm-modal/ssk-confirm.modal';

@Injectable({
  providedIn: 'root'
})
export class SelfServiceProvider {
  constructor(
    private readonly store: Store,
    private readonly modalService: ModalService
  ) {}

  cancelOrder() {
    this.modalService.showModal<
      boolean,
      {
        description?: string;
        title?: string;
        yesBtn?: SskButton;
        noBtn?: SskButton;
      }
    >(
      SskConfirmModal,
      {
        title: 'Are you sure you want to cancel?',
        description:
          'This will reset the order and you will have to sign in again.',
        yesBtn: { text: 'Cancel Order', color: 'danger', fill: 'solid' },
        // eslint-disable-next-line @typescript-eslint/quotes
        noBtn: { text: "No, Don't cancel" }
      },
      { cssClass: 'dynamic-height-modal' },
      ({ data }) => {
        if (data) {
          this.store.dispatch(new CancelOrder());
        }
      }
    );
  }
}
