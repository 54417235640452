import { format } from 'date-fns';
import { DateHelper } from 'src/app/helpers/date.helper';
import { GeneralHelper } from 'src/app/helpers/general.helper';

import {
  Ticket,
  TicketItemRequest,
  TicketItemType,
  TicketSource
} from 'src/app/models';

export class TicketHelper {
  static calculateFormattedTakeoutDateTime({
    ticket_date,
    meal_delivery_area_timing_id,
    scheduled_ticket_time
  }: Partial<Ticket>): string {
    return (
      format(DateHelper.parse(ticket_date), 'EEE, MMM dd, yyyy') +
      ' | ' +
      (meal_delivery_area_timing_id ? scheduled_ticket_time : 'ASAP')
    );
  }

  static initializeTicket(ticket: Ticket): Ticket {
    ticket.calculated_total = 0;
    ticket.mealplan_balance_used = 0;
    ticket.outstanding_balance = 0;
    ticket.pre_tax_subtotal = 0;
    ticket.total_base_price = 0;
    ticket.total_discounts = 0;
    ticket.tax_total = 0;
    ticket.ticket_items = [];
    ticket.can_be_cancelled = true;
    ticket.order_source = TicketSource.POS;
    return ticket;
  }

  static generateDeliveryTicketItem(
    name: string,
    pos_product_id: number,
    base_price: number
  ): TicketItemRequest {
    const request: TicketItemRequest = {
      device_ticket_item_uuid: GeneralHelper.generateUuid(),
      type: TicketItemType.PRODUCT,
      name,
      pos_product_id,
      base_price
    };
    return request;
  }
}
