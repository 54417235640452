import { createPropertySelectors, createSelector } from '@ngxs/store';
import { TicketsState, TicketsStateModel } from './tickets.state';
import _ from 'lodash';
import { MealPlanTicketInfo } from 'src/app/store/utils.selectors';

export class TicketsStateSelectors {
  static slices = createPropertySelectors<TicketsStateModel>(TicketsState);

  static getIsTicketCreating = createSelector(
    [TicketsStateSelectors.slices.isTicketCreating],
    (isTicketCreating) => isTicketCreating
  );

  static lastTicketTransaction = createSelector(
    [TicketsStateSelectors.slices.lastTicketTransaction],
    (lastTicketTransaction) => lastTicketTransaction
  );

  static hasLastTicketTransaction = createSelector(
    [TicketsStateSelectors.lastTicketTransaction],
    (lastTicketTransaction) => !!lastTicketTransaction
  );

  static isTicketVisible = createSelector(
    [TicketsStateSelectors.slices.isTicketVisible],
    (isTicketVisible) => isTicketVisible
  );

  static selectedTicket = createSelector(
    [TicketsStateSelectors.slices.selectedTicket],
    (selectedTicket) => selectedTicket
  );

  static selectedTicketId = createSelector(
    [TicketsStateSelectors.selectedTicket],
    (selectedTicket) => selectedTicket.data
  );

  static getIsTicketProcessing = createSelector(
    [TicketsStateSelectors.selectedTicket],
    (selectedTicket) => selectedTicket.isProcessing
  );

  static tickets = createSelector(
    [TicketsStateSelectors.slices.tickets],
    (tickets) => tickets
  );

  static ticketsById = createSelector(
    [TicketsStateSelectors.tickets],
    (tickets) => tickets.byId
  );

  static getTicketBySeatId = createSelector(
    [TicketsStateSelectors.ticketsById],
    (ticketsById) => (seatId: number) =>
      ticketsById && _.find(ticketsById, (ticket) => ticket.seat_id === seatId)
  );

  static normalizedTicket = createSelector(
    [TicketsStateSelectors.selectedTicketId, TicketsStateSelectors.ticketsById],
    (selectedTicketId, ticketsById) =>
      !!selectedTicketId && !!ticketsById ? ticketsById[selectedTicketId] : null
  );

  // move code from utils.selectors.ts to here (getMealPlanTicketInfo)
  static getMealPlanTicketInfo = createSelector(
    [TicketsStateSelectors.ticketsById, TicketsStateSelectors.selectedTicketId],
    (ticketsById, selectedTicketId) => {
      if (!ticketsById || !selectedTicketId) {
        return {} as Partial<MealPlanTicketInfo>;
      }

      const ticket = ticketsById[selectedTicketId];

      return <MealPlanTicketInfo>{
        mealplan_tender_type: ticket.mealplan_tender_type,
        meal_plan_id: ticket.meal_plan_id
      };
    }
  );

  static getSelectedDinerId = createSelector(
    [TicketsStateSelectors.ticketsById, TicketsStateSelectors.selectedTicketId],
    (ticketById, selectedTicketId) => {
      const ticket =
        ticketById && selectedTicketId && ticketById[selectedTicketId];

      return ticket?.diner_id;
    }
  );

  static getMaxLimitByCategory = createSelector(
    [TicketsStateSelectors.normalizedTicket],
    (ticket) => (categoryId: number) => {
      const category = ticket?.categories?.find(({ id }) => id === categoryId);
      return category ? category.max_items : -1;
    }
  );

  static getLastLocationTicket = createSelector(
    [TicketsStateSelectors.slices.lastLocationTickets],
    ({ data }) => _.last(data)
  );

  static ticketAllIds = createSelector(
    [TicketsStateSelectors.tickets],
    (tickets) => tickets.allIds
  );
}
