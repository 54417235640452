import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { DeviceGuard } from './guards/device.guard';
import { PageConstant, PagePath } from './constants';
import { SelfServiceGuard } from './guards/self-service.guard';
import { LoginResolver } from './resolvers/login.resolve';
import { SelfServiceOrderingResolver } from './resolvers/self-service-ordering.resolver';
import { UnlockTicketResolver } from './resolvers/unlock-ticket.resolve';
import { LeaveTakeoutDeliveryGuard } from 'src/app/guards/leave-takeout-delivery.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: PageConstant.DEVICE_REGISTRATION_PAGE,
    pathMatch: 'full'
  },
  {
    path: PageConstant.DEVICE_REGISTRATION_PAGE,
    loadChildren: () =>
      import(
        './pages/auth/device-registration/device-registration.module'
      ).then((m) => m.DeviceRegistrationPageModule)
  },
  {
    path: PageConstant.LOGIN_PAGE,
    loadChildren: () =>
      import('./pages/auth/login/login.module').then((m) => m.LoginPageModule),
    canActivate: [DeviceGuard]
  },
  {
    path: PageConstant.SUPPORT_PAGE,
    loadChildren: () =>
      import('./pages/support/support.module').then((m) => m.SupportPageModule)
  },
  {
    path: PageConstant.TRANSACTIONS_PAGE,
    resolve: {
      unlock: UnlockTicketResolver
    },
    loadChildren: () =>
      import('./pages/transactions/transactions.module').then(
        (m) => m.TransactionsPageModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: PageConstant.PAYMENTS_PAGE,
    loadChildren: () =>
      import('./pages/pos/payments/payments.module').then(
        (m) => m.PaymentsPageModule
      ),
    canActivate: [AuthGuard],
    canDeactivate: [
      // LeaveCheckoutPageGuard
    ]
  },
  //QS Pages
  {
    path: PageConstant.QUICK_SERVICE_PAGE,
    loadChildren: () =>
      import('./pages/pos/quick-service/quick-service.module').then(
        (m) => m.QuickServicePageModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: PageConstant.ON_HOLD_PAGE,
    loadChildren: () =>
      import('./pages/pos/quick-service/on-hold/on-hold.module').then(
        (m) => m.OnHoldPageModule
      ),
    canActivate: [AuthGuard]
  },
  //DR Pages
  {
    path: PageConstant.DINING_ROOM_PAGE,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/pos/dining').then((m) => m.DiningRoomComponentModule)
  },
  {
    path: PageConstant.DINING_HOST_MODE_PAGE,
    loadChildren: () =>
      import('./pages/pos/dining/host-mode/host-mode.module').then(
        (m) => m.HostModePageModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: PageConstant.CHECKOUT_PAGE,
    resolve: {
      unlock: UnlockTicketResolver
    },
    loadChildren: () =>
      import('./pages/pos/dining/table/checkout/checkout.module').then(
        (m) => m.CheckoutPageModule
      ),
    canActivate: [AuthGuard],
    canDeactivate: [
      // LeavePaymentPageGuard
    ]
  },
  // Takeout Delivery
  {
    path: PagePath.TAKEOUT_DELIVERY_PAGE,
    resolve: {
      unlock: UnlockTicketResolver
    },
    loadChildren: () =>
      import('./pages/takeout-delivery/takeout-delivery.module').then(
        (m) => m.TakeOutDeliveryModule
      ),
    canDeactivate: [LeaveTakeoutDeliveryGuard],
    canActivate: [AuthGuard]
  },

  {
    path: PageConstant.TABLES_ASSIGNMENT_PAGE,
    resolve: {
      unlock: UnlockTicketResolver
    },
    loadChildren: () =>
      import(
        './pages/pos/dining/tables-assignment/tables-assignment.module'
      ).then((m) => m.TablesAssignmentPageModule),
    canActivate: [AuthGuard]
  },
  //Self Service Page
  {
    path: PageConstant.SELF_SERVICE_SETUP_PAGE,
    loadChildren: () =>
      import('./pages/self-service/setup/self-service-setup.module').then(
        (m) => m.SelfServiceSetupPageModule
      ),
    canActivate: [DeviceGuard]
  },
  {
    path: PageConstant.SELF_SERVICE_ORDERING_PAGE,
    loadChildren: () =>
      import('./pages/self-service/ordering/self-service-ordering.module').then(
        (m) => m.SelfServiceOrderingPageModule
      ),
    resolve: [SelfServiceOrderingResolver],
    canActivate: [SelfServiceGuard]
  },
  {
    path: PageConstant.SELF_SERVICE_PAYMENT_PAGE,
    loadChildren: () =>
      import('./pages/self-service/payment/self-service-payment.module').then(
        (m) => m.SelfServicePaymentPageModule
      ),
    canActivate: [SelfServiceGuard]
  },
  {
    path: PageConstant.POS_MANAGER_PAGE,
    loadChildren: () =>
      import('./pages/pos-manager/pos-manager.module').then(
        (m) => m.PosManagerModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: PageConstant.SERVICES_BOOKING_PAGE,
    loadChildren: () =>
      import('./pages/location-type/services/booking/booking.module').then(
        (m) => m.BookingPageModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: PageConstant.SERVICES_APPOINTMENTS_PAGE,
    loadChildren: () =>
      import(
        './pages/location-type/services/appointments/appointments.module'
      ).then((m) => m.AppointmentsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: PageConstant.SERVICES_SEARCH_APPOINTMENTS_PAGE,
    loadChildren: () =>
      import(
        './pages/location-type/services/search-appointments/search-appointments-page.module'
      ).then((m) => m.SearchAppointmentsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: PageConstant.SERVICES_SHOP_PAGE,
    loadChildren: () =>
      import('./pages/location-type/services/shop/shop.module').then(
        (m) => m.ShopPageModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: PageConstant.POS_MANAGER_SCHEDULES_SERVICES_PAGE,
    loadChildren: () =>
      import(
        './pages/location-type/services/schedules-services/schedules-services.module'
      ).then((m) => m.SchedulesServicesModule),
    canActivate: [AuthGuard]
  },
  {
    path: PageConstant.CENSUS_REPORT,
    loadChildren: async () =>
      (await import('./pages/census-report/census-report.module'))
        .CensusReportModule,
    canActivate: [AuthGuard]
  },
  { path: '**', redirectTo: PageConstant.DEVICE_REGISTRATION_PAGE }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [LoginResolver]
})
export class AppRoutingModule {}
