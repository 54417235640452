import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import {
  AlertController,
  ModalController,
  NavController,
  ToastController
} from '@ionic/angular';
import { produce } from 'immer';
import {
  catchError,
  combineLatest,
  concat,
  EMPTY,
  filter,
  finalize,
  forkJoin,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  switchMap,
  tap,
  pipe,
  UnaryFunction
} from 'rxjs';
import { StateOverwrite, StateReset } from 'ngxs-reset-plugin';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
//----------------------------------------------
import {
  DeliveryType,
  LocationType,
  MacroGridView,
  MenuItemType,
  NormalizedEntities,
  NormalizedObject,
  PaymentRequestType,
  PosLocation,
  Seat,
  StateObject,
  Ticket,
  TicketItem,
  TicketItemStatus,
  TicketSource,
  TicketStatus
} from 'src/app/models';
import { NormalizedTicket } from 'src/app/models/normalized-models/normalized-ticket.model';
import { DiningService, ModalService, TicketService } from 'src/app/services';
import { GeneralHelper, MenuHelper, NormalizrHelper } from 'src/app/helpers';
import { PageConstant } from 'src/app/constants';
import {
  LOG_ROCKET_CUSTOM_EVENTS,
  LogRocketProvider,
  TicketProvider
} from 'src/app/providers';
import {
  AddDinerToTicket,
  AddDinerToTicketSuccess,
  AddTaxExemption,
  AddTicketDiscount,
  AddTicketDiscountError,
  AddTicketItems,
  AddTicketMealPlan,
  AddTicketMealPlanSuccess,
  AssignGuestMealPlan,
  // AssignResidentForGuestTicket,
  CancelServicesTicket,
  CancelTakeoutTicket,
  CancelTakeoutTicketSuccess,
  CancelTicket,
  ChangeTicketMeal,
  ChangeTicketMealSuccess,
  CleanSelectTicket,
  CloseTicket,
  CloseTicketError,
  CloseTicketSuccess,
  CloseTicketWsEvent,
  CreateDraftTicket,
  CreateTicket,
  CreateTicketError,
  CreateTicketForSeatSuccess,
  CreateTicketSuccess,
  CreateTicketWsEvent,
  CreateTicketWsEventSuccess,
  FireIndividualOrder,
  FireListIndividualOrder,
  FireTicketAndClose,
  FireTicketAndCloseError,
  GetLastQuickServiceTicket,
  GetLastQuickServiceTicketSuccess,
  GetLocationTickets,
  GetTicketsSuccess,
  LoadTicketDetail,
  LoadTicketDetailSuccess,
  LockOrUnlockTicketSuccess,
  LockTicket,
  MealPlanPaymentError,
  PrintLastReceipt,
  PrintTicket,
  RemoveAllSskTicketItems,
  RemoveAllSskTicketItemsError,
  RemoveAllSskTicketItemsSuccess,
  RemoveDinerFromTicket,
  RemoveDinerFromTicketError,
  RemoveDinerFromTicketSuccess,
  RemoveGuestOfDinerFromTicket,
  RemoveTaxExemption,
  RemoveTicketDiscount,
  RemoveTicketItem,
  RemoveTicketItemSuccess,
  RemoveTicketMealPlan,
  RemoveTicketSuccess,
  SelectBackQSTicket,
  SelectTicket,
  SetTicketCreatingStatus,
  SetupTakeoutOrder,
  SetupTakeoutOrderError,
  SetupTakeoutOrderSuccess,
  TemporarySeatMealChange,
  TemporarySeatMealChangeSuccess,
  TicketClosedWsEvent,
  TicketLockedChangedFromWS,
  ToggleTicketVisibility,
  UnlockTicket,
  UpdateFireTableSuccess,
  UpdateLocalTicketRelationships,
  UpdateTicket,
  UpdateTicketSuccess,
  VoidTicket
} from './tickets.action';
import { SEATS_STATE_TOKEN } from 'src/app/store/seats/seats.state';
import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';
import {
  ConfirmModal,
  ConfirmModalDismissData
} from 'src/app/modals/confirm/confirm.modal';
import {
  DINERS_STATE_TOKEN,
  DinersState
} from 'src/app/store/diners/diners.state';
import {
  TICKET_ITEMS_STATE_TOKEN,
  TicketItemsState
} from 'src/app/store/ticket-items/ticket-items.state';
import { TransactionsState } from 'src/app/store/transactions/transactions.state';
import { TicketHelper } from 'src/app/helpers/ticket.helper';
import { TAKEOUT_DELIVERY_STATE_TOKEN } from 'src/app/store/takeout-delivery/takeout-delivery.state';
import { GetTableSuccess } from 'src/app/store/tables/tables.action';
import {
  SelectDiner,
  UpdateChargeAccountBalance
} from 'src/app/store/diners/diners.action';
import {
  SelectNewSeat,
  SelectSeat,
  UnselectSeat,
  UpdateLocalSeat
} from 'src/app/store/seats/seats.action';
import {
  MakePayment,
  MakePaymentSuccess
} from 'src/app/store/payment/payment.action';
import {
  SelectTicketItem,
  UpdateTicketItemSuccess,
  VoidTicketItemSuccess
} from 'src/app/store/ticket-items/ticket-items.action';
import { LOCATION_STATE_TOKEN } from 'src/app/store/location/location.state';
import { OpenCashDrawer } from 'src/app/store/app/app.action';
import { ExitTakeOutDelivery } from 'src/app/store/takeout-delivery/takeout-delivery.action';
import {
  SetSelectedMealId,
  ShowModifierGrid,
  UpdateMacroGridView
} from 'src/app/store/menu/menu.action';
import { UpdateSelectedMealId } from 'src/app/store/location/location.action';
import { patch } from '@ngxs/store/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { HARDWARE_DEVICE_STATE_TOKEN } from 'src/app/store/hardware-device/hardware-device.model';
import { ExceptionError } from 'src/app/store/error/error.action';
import { MENU_STATE_TOKEN } from 'src/app/store/menu/menu.state.model';
import { DinerHelper } from 'src/app/helpers/diner.helper';
import { AppStateHelper } from 'src/app/store/app/app.state.helper';
import { LocationStateHelper } from 'src/app/store/location/location.state.helper';

export interface TicketsStateModel {
  tickets: NormalizedObject<NormalizedTicket>;
  selectedTicket: StateObject<string>;
  lastLocationTickets: StateObject<string[]>;
  isTicketVisible: boolean;
  lastTicketTransaction: string;
  isTicketCreating: boolean;
}

export const TICKETS_STATE_TOKEN = new StateToken<TicketsStateModel>('tickets');

export const DEFAULT_TICKET_STATE: TicketsStateModel = {
  tickets: {
    byId: {},
    allIds: []
  },
  selectedTicket: { data: null, isProcessing: false },
  lastLocationTickets: { data: [], isProcessing: false },
  isTicketVisible: false,
  isTicketCreating: false,
  lastTicketTransaction: null
};

@State({
  name: TICKETS_STATE_TOKEN,
  defaults: DEFAULT_TICKET_STATE
})
@Injectable()
export class TicketsState {
  constructor(
    private readonly store: Store,
    private readonly toastController: ToastController,
    private readonly modalController: ModalController,
    private readonly router: Router,
    private readonly ticketProvider: TicketProvider,
    private readonly navController: NavController,
    private readonly ticketService: TicketService,
    private readonly logRocketProvider: LogRocketProvider,
    private readonly alertController: AlertController,
    private readonly modalService: ModalService,
    private readonly diningService: DiningService
  ) {}

  @Action(GetTableSuccess)
  getTablesSuccess(
    ctx: StateContext<TicketsStateModel>,
    { table }: GetTableSuccess
  ) {
    const _state = ctx.getState();
    const tickets = _.flatMap(table.seats, (seat) => (<Seat>seat).ticket)
      .filter((i) => !!i)
      .map((data) => {
        const ticket = _.cloneDeep(data);
        ticket.diner = { id: ticket.diner_id, first_name: '', last_name: '' };
        ticket.ticket_items = ticket.ticket_items.map((ti) =>
          this.addTicketItemLocalAttributes(ti, ticket.ticket_items)
        );
        if (
          ticket.device_ticket_uuid === _state.selectedTicket.data &&
          _state.tickets.byId &&
          _state.tickets.byId[_state.selectedTicket.data]
        ) {
          ticket.locked =
            _state.tickets.byId[_state.selectedTicket.data].locked;
          ticket.locked_by_operator_id =
            _state.tickets.byId[
              _state.selectedTicket.data
            ].locked_by_operator_id;
        }
        return ticket;
      });

    const entities = NormalizrHelper.normalizeTickets(tickets).entities;

    ctx.setState(
      produce((draft: TicketsStateModel) => {
        if (entities.tickets) {
          draft.tickets = {
            byId: { ...draft.tickets.byId, ...entities.tickets },
            allIds: _.union(draft.tickets.allIds, Object.keys(entities.tickets))
          };
        }
      })
    );

    //Select the Ticket diner if selecting the seat from Floor Plan)
    const state = ctx.getState();

    if (
      state.selectedTicket.data &&
      state.tickets.byId[state.selectedTicket.data]?.diner_id
    ) {
      this.selectDiner(ctx, state.selectedTicket.data);
    }

    if (state.selectedTicket.data) {
      const normalizedTicket = state.tickets.byId[state.selectedTicket.data];
      const ticketDate = normalizedTicket?.ticket_date;
      // fetch the menu by ticket meal id for clicking the seat directly in the seat diagram
      this.updateMenuForTicket(ctx, ticketDate, state.selectedTicket.data);
    }

    ctx.dispatch(new GetTicketsSuccess(entities));
  }

  @Action(LoadTicketDetail)
  loadTicketDetail(
    ctx: StateContext<TicketsStateModel>,
    { ticketUuid, selectTicket }: LoadTicketDetail
  ) {
    return this.ticketService.getTicket(ticketUuid).pipe(
      switchMap((ticket) =>
        ticket.status === TicketStatus.CLOSED
          ? EMPTY
          : ctx.dispatch(
              new LoadTicketDetailSuccess(
                {
                  ...ticket,
                  diner: { id: ticket.diner_id, first_name: '', last_name: '' },
                  ticket_items: ticket.ticket_items
                    .map((ti) =>
                      this.addTicketItemLocalAttributes(ti, ticket.ticket_items)
                    )
                    .filter((ti) => !ti.parent_uuid)
                },
                selectTicket
              )
            )
      )
    );
  }

  @Action(SelectTicket)
  selectTicket(
    ctx: StateContext<TicketsStateModel>,
    { ticketId }: SelectTicket
  ) {
    const state = ctx.getState();
    const operator = this.store.selectSnapshot(APP_STATE_TOKEN).operator;
    let currentTicket =
      state.tickets.byId && state.tickets.byId[state.selectedTicket.data];

    if (
      state.selectedTicket.data &&
      state.selectedTicket.data !== ticketId && // only unlock the old ticket
      currentTicket &&
      currentTicket.locked &&
      currentTicket.locked_by_operator_id === operator.id
    ) {
      ctx.dispatch(new UnlockTicket(state.selectedTicket.data));
    }

    ctx.setState(
      patch({
        selectedTicket: patch({
          data: ticketId
        })
      })
    );
    this.selectDiner(ctx, ticketId);
    const actions: any[] = [new ShowModifierGrid(false)];

    if (ticketId) {
      currentTicket = state.tickets.byId && state.tickets.byId[ticketId];
      actions.push(new ToggleTicketVisibility(false));

      // fetch the meal base on the ticket meal id, it may cause a issue if the state.ticket.byId is empty. In UI, it's the case clicking on the seat directly.
      // Need to add more handler on the select seat.
      this.updateMenuForTicket(
        ctx,
        currentTicket?.ticket_date || this.formatDate(new Date()),
        ticketId,
        actions
      );
    }

    //Lock selected ticket if in Dining Room and not takeout delivery
    const locationType =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location.type;
    const isDiningRoom: boolean = locationType === LocationType.DiningRoom;
    const isTakeoutDelivery = this.store.selectSnapshot(
      TAKEOUT_DELIVERY_STATE_TOKEN
    ).enableTakeOutDelivery;
    if (ticketId && isDiningRoom && !isTakeoutDelivery) {
      actions.push(new LockTicket());
    }

    //Clear ticket item selection if selecting a new ticket
    const selectedTicketItemId = this.store.selectSnapshot(
      TICKET_ITEMS_STATE_TOKEN
    ).selectedTicketItem;
    if (selectedTicketItemId) {
      actions.push(new SelectTicketItem());
    }

    return ctx.dispatch(actions);
  }

  @Action(CreateTicket)
  createTicket(
    ctx: StateContext<TicketsStateModel>,
    { defaults }: CreateTicket
  ) {
    return this.ticketService.createTicket(defaults).pipe(
      switchMap((_ticket) => {
        // to ensure that we dont miss order_source & delivery_type
        const ticket = { ..._ticket, ...defaults };
        const actions: any[] = [new SelectTicket()];
        const isDiningRoom =
          this.store.selectSnapshot(LOCATION_STATE_TOKEN).location.type ===
          LocationType.DiningRoom;

        ctx.patchState({ lastTicketTransaction: null });

        this.logRocketProvider.track(LOG_ROCKET_CUSTOM_EVENTS.Ticket.Created, {
          device_ticket_uuid: ticket.device_ticket_uuid,
          id: ticket.id
        });

        actions.push(new CreateTicketSuccess(ticket));

        if (isDiningRoom) {
          actions.push(
            new UpdateLocalSeat({
              id: ticket.seat_id,
              tableId: ticket.table_id,
              ticket: ticket.device_ticket_uuid,
              device_ticket_uuid: ticket.device_ticket_uuid,
              ticket_created_at: ticket.created_at,
              ticket_status: ticket.status
            })
          );
        }

        // in DR, when the previous ticket with different meal id and create a new ticket with another meal id, need to fetch the menu again.
        this.updateMenuForTicket(
          ctx,
          ticket.ticket_date,
          ticket.device_ticket_uuid,
          actions
        );

        return ctx.dispatch(actions);
      }),
      catchError(() =>
        ctx.dispatch(
          new CreateTicketError(`There was a problem creating the ticket.`)
        )
      )
    );
  }

  @Action([
    CreateTicketSuccess,
    AddTicketMealPlanSuccess,
    GetLastQuickServiceTicketSuccess,
    CreateTicketWsEventSuccess,
    LoadTicketDetailSuccess,
    VoidTicketItemSuccess
  ])
  updateLocalTicket(
    ctx: StateContext<TicketsStateModel>,
    { ticket, selectTicket }: CreateTicketSuccess
  ) {
    const entities = NormalizrHelper.normalizeTickets([ticket]).entities;

    this.addTicketToState(ctx, ticket, selectTicket, entities);

    if (selectTicket && ticket?.id) {
      this.selectDiner(ctx, ticket.device_ticket_uuid);
      const locationType =
        this.store.selectSnapshot(LOCATION_STATE_TOKEN).location.type;
      const isDiningRoom: boolean = locationType === LocationType.DiningRoom;

      const isTakeoutDelivery = this.store.selectSnapshot(
        TAKEOUT_DELIVERY_STATE_TOKEN
      ).enableTakeOutDelivery;
      if (isDiningRoom && !isTakeoutDelivery) {
        ctx.dispatch(new LockTicket());
      }
    }

    if (entities.ticket_items || entities.transactions || entities.diners) {
      ctx.dispatch(new UpdateLocalTicketRelationships(entities));
    }
  }

  @Action(UpdateTicket)
  updateTicket(ctx: StateContext<TicketsStateModel>, { ticket }: UpdateTicket) {
    return this.ticketService
      .updateTicket({
        ...ticket,
        device_ticket_uuid:
          ticket.device_ticket_uuid || ctx.getState().selectedTicket.data
      })
      .pipe(
        switchMap((responseTicket) =>
          ctx.dispatch(new UpdateTicketSuccess(responseTicket))
        )
      );
  }

  @Action(CancelTicket)
  cancelTicket(
    ctx: StateContext<TicketsStateModel>,
    { ticketUuid }: CancelTicket
  ) {
    const state = ctx.getState();
    const deviceTicketUuid = ticketUuid || state.selectedTicket.data;
    const ticketById = state.tickets.byId;
    const isTicketNotBeenDeleting =
      this.ticketService.deletingTickets.indexOf(deviceTicketUuid) === -1;
    const prevTicket = ticketById && ticketById[deviceTicketUuid];

    if (_.isNil(prevTicket?.id)) {
      return ctx.dispatch(new RemoveTicketSuccess(deviceTicketUuid, true));
    }

    if (isTicketNotBeenDeleting) {
      this._setTicketProcessing(ctx, true);

      return this.ticketService.deleteTicket(deviceTicketUuid).pipe(
        switchMap(() => {
          const ticket = ticketById[deviceTicketUuid];
          this.logRocketProvider.track(
            LOG_ROCKET_CUSTOM_EVENTS.Ticket.Cancelled,
            { device_ticket_uuid: ticket?.device_ticket_uuid, id: ticket?.id }
          );
          return ctx.dispatch(new RemoveTicketSuccess(deviceTicketUuid, true));
        }),
        finalize(() => this._setTicketProcessing(ctx, false))
      );
    }
  }

  @Action(CancelTakeoutTicket)
  cancelTakeoutTicket(
    ctx: StateContext<TicketsStateModel>,
    { ticket_uuid, operator_id }: CancelTakeoutTicket
  ) {
    return this.ticketService.cancelTicket(ticket_uuid, operator_id).pipe(
      tap(async (data) => {
        console.log('cancel ticket ', data);
        const isDiningRoom =
          this.store.selectSnapshot(LOCATION_STATE_TOKEN).location.type ===
          LocationType.DiningRoom;

        if (data.success) {
          this.navController.navigateRoot(
            PageConstant.TAKEOUT_DELIVERY_PAGE(isDiningRoom),
            {
              animated: true,
              animationDirection: 'back'
            }
          );
          ctx.dispatch(new CancelTakeoutTicketSuccess());
        } else {
          const alert = await this.alertController.create({
            header: 'Error Refunding',
            message: `${data.message}<br/><br/>Please contact MealSuite Support and reference the above error for assistance.`,
            cssClass: 'custom-alert-error',
            buttons: ['OK']
          });
          alert.present();
        }
      })
    );
  }

  @Action(VoidTicket)
  voidTicket(ctx: StateContext<TicketsStateModel>, payload: VoidTicket) {
    const state = ctx.getState();
    const selectedTicketId = state.selectedTicket.data;
    if (this.ticketService.deletingTickets.indexOf(selectedTicketId) === -1) {
      return this.ticketService.voidTicket(selectedTicketId, payload).pipe(
        tap(() => {
          const ticket = state.tickets.byId[selectedTicketId];
          if (ticket) {
            this.logRocketProvider.track(
              LOG_ROCKET_CUSTOM_EVENTS.Ticket.Voided,
              {
                device_ticket_uuid: ticket.device_ticket_uuid,
                id: ticket.id
              }
            );
          }
          ctx.dispatch(new RemoveTicketSuccess(selectedTicketId, true));
        })
      );
    }
  }

  @Action(FireIndividualOrder)
  fireIndividualOrder(
    ctx: StateContext<TicketsStateModel>,
    { ticketItemUuid, course, seat }: FireIndividualOrder
  ) {
    const state = ctx.getState();
    const operatorId = this.store.selectSnapshot(APP_STATE_TOKEN).operator.id;
    const dispatchActions = (
      ticketItem: Partial<TicketItem>[],
      ticket_uuid: string
    ) => {
      ctx.dispatch([
        new UpdateTicketSuccess({
          can_be_cancelled: false,
          device_ticket_uuid: ticket_uuid
        }),
        new UpdateTicketItemSuccess(ticketItem)
      ]);
    };
    let data: Partial<{
      device_ticket_item_uuid: string;
      status: TicketItemStatus;
    }>[] = [];
    // Fire Ticket on the ticket component
    const selectedId = state.selectedTicket.data;
    if (!ticketItemUuid && !isFinite(course)) {
      // fire whole tickets
      return this.ticketService.fireTicket(selectedId, operatorId).pipe(
        tap((ticket_uuid) => {
          if (ticket_uuid) {
            const ticketItemUuids =
              state.tickets.byId[selectedId]?.ticket_items || [];

            data = ticketItemUuids.map<Partial<TicketItem>>(
              (device_ticket_item_uuid) =>
                Object.assign(
                  {},
                  { device_ticket_item_uuid, status: TicketItemStatus.FIRED }
                )
            );

            dispatchActions(data, ticket_uuid);
          }
        })
      );
    }
    // Fire Specific Item
    if (ticketItemUuid) {
      return this.ticketService
        .firePartialTickets(operatorId, ticketItemUuid)
        .pipe(
          tap((success) => {
            const ticketItems = this.store.selectSnapshot(
              TICKET_ITEMS_STATE_TOKEN
            ).ticketItems.byId;
            const selectedTicketItem = ticketItems[ticketItemUuid];
            // update ticket status
            if (success) {
              data = [
                ticketItemUuid,
                ..._.toArray(selectedTicketItem.ticket_items)
              ].map((device_ticket_item_uuid) => ({
                device_ticket_item_uuid,
                status: TicketItemStatus.FIRED
              }));
              dispatchActions(data, selectedId);
            }
          })
        );
    }
    // Fire ticket course on the ticket component
    const ticketUuid =
      (seat && seat.ticket && seat.ticket.device_ticket_uuid) || selectedId;
    return this.ticketService
      .firePartialTickets(operatorId, null, course, ticketUuid)
      .pipe(
        tap((success) => {
          // update ticket status
          if (success && state.tickets.byId && state.tickets.byId[ticketUuid]) {
            const ticketItemUuids = state.tickets.byId[ticketUuid].ticket_items;
            const ticketItems = this.store.selectSnapshot(
              TICKET_ITEMS_STATE_TOKEN
            ).ticketItems.byId;

            data = ticketItemUuids
              .map<Partial<TicketItem>>((device_ticket_item_uuid) => {
                const ticketItem = ticketItems[device_ticket_item_uuid];
                if (ticketItem && ticketItem.course === course) {
                  return Object.assign(
                    {},
                    { device_ticket_item_uuid, status: TicketItemStatus.FIRED }
                  );
                }
              })
              .filter(Boolean);
            dispatchActions(data, ticketUuid);
          }
        })
      );
  }

  @Action(FireListIndividualOrder)
  fireListIndividualOrder(
    ctx: StateContext<TicketsStateModel>,
    { listIndividualOrder, seat }: FireListIndividualOrder
  ) {
    return combineLatest(
      listIndividualOrder.map(({ ticketItemUuid, course }) =>
        ctx.dispatch(new FireIndividualOrder(ticketItemUuid, course, seat))
      )
    );
  }

  @Action(UpdateFireTableSuccess)
  updateFireTableSuccess(
    ctx: StateContext<TicketsStateModel>,
    { tableId, course }: UpdateFireTableSuccess
  ) {
    const state = ctx.getState();
    if (!state.tickets.byId) {
      return;
    }
    const ticketItems = this.store.selectSnapshot(TICKET_ITEMS_STATE_TOKEN)
      .ticketItems.byId;

    let data: Partial<TicketItem>[] = [];
    const tableTicketUuids = state.tickets.allIds.filter(
      (ticketUuid) => state.tickets.byId[ticketUuid]?.table_id === tableId
    );

    tableTicketUuids.forEach((ticketUuid) => {
      const ticketItemUuids = state.tickets.byId[ticketUuid].ticket_items;
      data = data.concat(
        ticketItemUuids
          .map<Partial<TicketItem>>((device_ticket_item_uuid) => {
            const ticketItem = _.has(ticketItems, device_ticket_item_uuid)
              ? ticketItems[device_ticket_item_uuid]
              : null;
            // DEF-4153 if(!course) always true if course is 0 causing all tickets marked as Fired
            if (
              _.isNil(course) ||
              (ticketItem && ticketItem.course === course)
            ) {
              return Object.assign(
                {},
                { device_ticket_item_uuid, status: TicketItemStatus.FIRED }
              );
            }
          })
          .filter(Boolean)
      );

      //Update can_be_cancelled on each ticket
      ctx.setState(
        produce((draft: TicketsStateModel) => {
          draft.tickets.byId[ticketUuid].can_be_cancelled = false;
        })
      );
    });

    ctx.dispatch(new UpdateTicketItemSuccess(data));
  }

  private static doPrintTicket(
    {
      type,
      print_receipt_for_non_cash,
      print_receipt_for_cash_payment,
      auto_close_on_full_payment
    }: PosLocation,
    { outstanding_balance, order_source }: NormalizedTicket,
    paymentRequestTypes: PaymentRequestType[]
  ): boolean {
    if (type === LocationType.SelfServe) {
      return print_receipt_for_non_cash;
    }

    // DEF-5628: Ticket has been printed on payment success, so no need to print again
    if (
      order_source === TicketSource.POS_TAKEOUT &&
      auto_close_on_full_payment
    ) {
      return false;
    }

    const paymentTypes = paymentRequestTypes || [],
      isCashPayment = paymentTypes.includes(PaymentRequestType.CASH),
      hasPaymentOrZeroBalance = paymentTypes.length || !outstanding_balance;

    return isCashPayment
      ? print_receipt_for_cash_payment
      : hasPaymentOrZeroBalance && print_receipt_for_non_cash;
  }

  @Action(CloseTicket)
  closeTicket(ctx: StateContext<TicketsStateModel>, payload: CloseTicket) {
    const state = ctx.getState();
    const paymentTypes = payload.paymentTypes;
    const ticketUuid = payload.ticketUuid || state.selectedTicket.data;
    const ticket = state.tickets.byId[ticketUuid];
    const location = this.store.selectSnapshot(LOCATION_STATE_TOKEN).location;
    const isSSK = location.type === LocationType.SelfServe;
    const actions: any[] = [];
    const doPrintTicket = TicketsState.doPrintTicket(
      location,
      ticket,
      paymentTypes
    );

    ctx.patchState({ lastTicketTransaction: ticketUuid });

    if (doPrintTicket) {
      actions.push(new PrintTicket(ticketUuid, false));
    }

    actions.push(new RemoveTicketSuccess(ticketUuid, true));

    //If ticket is closed on self serve kiosk screen, dispatch close ticket success action
    if (isSSK) {
      actions.push(new CloseTicketSuccess());
    }

    if (ticket?.status === TicketStatus.CLOSED) {
      return ctx.dispatch(actions);
    }

    return this.ticketService.closeTicket(ticketUuid).pipe(
      tap(() => {
        this.logRocketProvider.track(LOG_ROCKET_CUSTOM_EVENTS.Ticket.Closed, {
          device_ticket_uuid: ticket?.device_ticket_uuid,
          id: ticket?.id
        });
        ctx.dispatch(actions);
      }),
      catchError((httpError: HttpErrorResponse) => {
        this._setTicketProcessing(ctx, false);

        const error = httpError.error;
        if (!error) {
          return ctx.dispatch(
            new CloseTicketError('There was a problem closing the ticket')
          );
        }

        const { message, status } = error;
        return status === 'already_closed'
          ? ctx.dispatch(actions)
          : ctx.dispatch(
              message?.includes('Insufficient mealplan')
                ? new MealPlanPaymentError(ticketUuid, message)
                : new CloseTicketError('There was a problem closing the ticket')
            );
      })
    );
  }

  @Action(MealPlanPaymentError)
  MealPlanPaymentError(
    ctx: StateContext<TicketsStateModel>,
    payload: MealPlanPaymentError
  ) {
    this.modalService.showModal<ConfirmModalDismissData>(
      ConfirmModal,
      {
        header: 'Meal Plan Payment Error',
        subTitle: payload.errorMessage,
        redBtnHidden: true,
        grayBtnLabel: 'Remove Meal Plan'
      },
      {
        cssClass: ['pos-confirm-modal', 'dynamic-height-modal'],
        backdropDismiss: false
      },
      () => ctx.dispatch(new RemoveTicketMealPlan(payload.ticketUuid))
    );
  }

  @Action(AddTicketMealPlan)
  async addTicketMealPlan(
    ctx: StateContext<TicketsStateModel>,
    { mealPlanId, ticketUuid }: AddTicketMealPlan
  ) {
    const state = ctx.getState();
    const uid = ticketUuid || state.selectedTicket.data;

    return this._assignMealPlanToTicket(uid, mealPlanId).pipe(
      tap((ticket) =>
        ctx.dispatch([
          new AddTicketMealPlanSuccess(ticket),
          new UpdateTicketSuccess(_.cloneDeep(ticket))
        ])
      )
    );
  }

  @Action(RemoveTicketMealPlan)
  removeTicketMealPlan(
    ctx: StateContext<TicketsStateModel>,
    { ticketUuid }: RemoveTicketMealPlan
  ) {
    return this.ticketService.removeMealPlan(ticketUuid).pipe(
      tap((ticket) => {
        const actions = [
          new UpdateTicketItemSuccess(ticket.ticket_items),
          new UpdateTicketSuccess({
            ...ticket,
            meal_plan: null
          })
        ];
        ctx.dispatch(actions);
      })
    );
  }

  @Action(AddTicketDiscount)
  addTicketDiscount(
    ctx: StateContext<TicketsStateModel>,
    { discount_id }: AddTicketDiscount
  ) {
    const state = ctx.getState();
    const operator = this.store.selectSnapshot(APP_STATE_TOKEN).operator;
    return this.ticketService
      .addTicketDiscount(state.selectedTicket.data, operator.id, discount_id)
      .pipe(
        switchMap((ticket) => {
          this.modalController.dismiss();
          if (!ticket) {
            ctx.dispatch(new ExceptionError('Ticket not found'));
            return;
          }

          const actions = [
            new UpdateTicketSuccess(ticket),
            new UpdateTicketItemSuccess(ticket.ticket_items)
          ];
          return ctx.dispatch(actions);
        }),
        catchError((err) => ctx.dispatch(new AddTicketDiscountError(err)))
      );
  }

  @Action(RemoveTicketDiscount)
  removeTicketDiscount(
    ctx: StateContext<TicketsStateModel>,
    { discount_id }: RemoveTicketDiscount
  ) {
    const state = ctx.getState();
    return this.ticketService
      .removeTicketDiscount(state.selectedTicket.data, discount_id)
      .pipe(
        tap((ticket) => {
          ctx.dispatch(
            new UpdateTicketSuccess({
              ...ticket,
              applied_discount_id: null,
              total_discounts: 0
            })
          );
          ctx.dispatch(new UpdateTicketItemSuccess(ticket.ticket_items));
        })
      );
  }

  @Action(AddTicketMealPlanSuccess)
  autoAddMealPlanItems(
    { getState }: StateContext<TicketsStateModel>,
    { ticket }: AddTicketMealPlanSuccess
  ) {
    const { currentMealId, location } =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN);
    const autoAddItems: number[] =
      LocationStateHelper.getAutoAddProductsForMealPlan(
        currentMealId,
        location
      );
    const isSelfServicePaymentPage = this.router.url.includes(
      PageConstant.SELF_SERVICE_PAYMENT_PAGE
    );

    const ticketMealId = ticket?.meal_id;

    if (
      ticketMealId &&
      autoAddItems &&
      autoAddItems?.length > 0 &&
      !isSelfServicePaymentPage
    ) {
      const { selected_macro_grid, menu, menu_tally } =
        this.store.selectSnapshot(MENU_STATE_TOKEN);
      const dinerState = this.store.selectSnapshot(DINERS_STATE_TOKEN);
      const locationType =
        this.store.selectSnapshot(LOCATION_STATE_TOKEN).location.type;
      const state = getState();
      const ticketsById = state.tickets.byId;
      const selectedTicketId = state.selectedTicket.data;

      const menuItems = MenuHelper.getMenuItems(
        selected_macro_grid,
        menu,
        menu_tally,
        DinerHelper.getTicketDiner(
          dinerState.diners.byId,
          ticketsById[selectedTicketId].diner_id
        ),
        locationType === LocationType.Services,
        ticketMealId
      );

      const autoItems = (menuItems || [])?.filter(
        (x) =>
          x && x.type === MenuItemType.Product && autoAddItems.includes(x.id)
      );
      autoItems.forEach((item) => {
        this.ticketProvider.ticketItemAddingHandler({ ...item, quantity: 1 });
      });
    }
  }

  @Action(RemoveTicketSuccess)
  removeTicketSuccess(
    ctx: StateContext<TicketsStateModel>,
    payload: RemoveTicketSuccess
  ) {
    return this.removeTicketSuccessHandler(ctx, payload);
  }

  @Action(AddDinerToTicket)
  addDinerToTicket(
    ctx: StateContext<TicketsStateModel>,
    { diner, forceApplyMealPlan }: AddDinerToTicket
  ) {
    const location = this.store.selectSnapshot(LOCATION_STATE_TOKEN).location;
    const isDiningRoom = location.type === LocationType.DiningRoom;
    const state = ctx.getState();
    const selectedTicket =
      state.tickets.byId && state.tickets.byId[state.selectedTicket.data];
    const isNotPOSTakeOutOrder =
      selectedTicket?.order_source !== TicketSource.POS_TAKEOUT;
    const seatId =
      isDiningRoom && isNotPOSTakeOutOrder
        ? this.store.selectSnapshot(SEATS_STATE_TOKEN).seats.byId[
            selectedTicket.seat_id
          ].id
        : null;
    const isTicketCreated = !!selectedTicket?.status;
    const assignDiner = () =>
      this.ticketService.assignDinerToTicket(diner.id).pipe(
        tap((res) => {
          ctx.dispatch([
            new AddDinerToTicketSuccess(
              diner,
              true,
              true,
              forceApplyMealPlan,
              seatId
            ),
            new LoadTicketDetailSuccess(res)
          ]);
        })
      );

    return isTicketCreated
      ? selectedTicket.diner_id
        ? ctx
            .dispatch(new RemoveDinerFromTicket())
            .pipe(switchMap(() => assignDiner()))
        : assignDiner()
      : ctx.dispatch(new CreateTicket()).pipe(switchMap(() => assignDiner()));
  }

  @Action(AddDinerToTicketSuccess)
  addDinerToTicketSuccess(
    ctx: StateContext<TicketsStateModel>,
    {
      diner,
      addMealPlan,
      getDinerDetail,
      forceApplyMealPlan
    }: AddDinerToTicketSuccess
  ) {
    ctx.setState(
      produce((draft: TicketsStateModel) => {
        const selectedDraftTicketId = draft.selectedTicket.data;
        if (draft.tickets.byId[selectedDraftTicketId]) {
          draft.tickets.byId[selectedDraftTicketId].diner = diner.id;
          draft.tickets.byId[selectedDraftTicketId].diner_id = diner.id;

          draft.tickets.byId[selectedDraftTicketId].diner_name =
            diner.name || `${diner.first_name} ${diner.last_name}`;
        }
      })
    );

    const state = ctx.getState();
    const selectedTicketId = state.selectedTicket.data;
    this.selectDiner(
      ctx,
      selectedTicketId,
      addMealPlan,
      getDinerDetail,
      forceApplyMealPlan
    );
    this.updateDinerToLocalSeat(ctx, selectedTicketId);
  }

  @Action([RemoveDinerFromTicket, RemoveGuestOfDinerFromTicket])
  removeDinerFromTicket(
    ctx: StateContext<TicketsStateModel>,
    action: RemoveDinerFromTicket | RemoveGuestOfDinerFromTicket
  ) {
    const state = ctx.getState();
    const selectedTicketId = state.selectedTicket.data;
    const isGuestOfDiner = action instanceof RemoveGuestOfDinerFromTicket;

    if (
      !state.tickets.byId[selectedTicketId]?.diner_id &&
      !state.tickets.byId[selectedTicketId]?.guest_of_diner_id
    ) {
      return;
    }

    if (state.tickets.byId[selectedTicketId].status === 'partial_paid') {
      return ctx.dispatch(
        new RemoveDinerFromTicketError(`You cannot remove a ${
          isGuestOfDiner ? 'guest of diner' : 'diner'
        } from a partially paid ticket.
      <p> You can only change the ticket assignment. </p>`)
      );
    }

    const ticket = state.tickets.byId[selectedTicketId];
    // If the ticket is assigned to a diner, remove the diner.
    // If the ticket is assigned to a guest of a diner, remove the guest of the diner
    const removeDinerService = isGuestOfDiner
      ? this.ticketService.updateTicket({ guest_of_diner_id: null })
      : this.ticketService.clearDinerAssignment();
    const removeDiner$ = removeDinerService.pipe(
      switchMap((res) => ctx.dispatch(new RemoveDinerFromTicketSuccess(res))),
      catchError((error: HttpErrorResponse) =>
        ctx.dispatch(
          new RemoveDinerFromTicketError(JSON.stringify(error.error))
        )
      )
    );

    // If the ticket has a meal plan, remove the meal plan first and then run the remove diner service to avoid meal plan errors
    const hasMealPlan = !!ticket.meal_plan;
    const removeMealPlan = () =>
      ctx
        .dispatch(new RemoveTicketMealPlan(ticket.device_ticket_uuid))
        .pipe(mergeMap(() => removeDiner$));
    return hasMealPlan ? removeMealPlan() : removeDiner$;
  }

  @Action(RemoveDinerFromTicketSuccess)
  removeDinerFromTicketSuccess(
    ctx: StateContext<TicketsStateModel>,
    payload: RemoveDinerFromTicketSuccess
  ) {
    const state = ctx.getState();
    const selectedTicketId = state.selectedTicket.data;
    const ticket = state.tickets.byId[selectedTicketId];

    this.logRocketProvider.track(LOG_ROCKET_CUSTOM_EVENTS.Diner.Unassigned, {
      device_ticket_uuid: ticket.device_ticket_uuid,
      diner_name: ticket.diner_name,
      diner_id: ticket.diner_id
    });

    ctx.setState(
      produce((draft: TicketsStateModel) => {
        const selectedDraftTickedId = draft.selectedTicket.data;
        if (draft.tickets.byId[selectedDraftTickedId]) {
          draft.tickets.byId[selectedDraftTickedId].diner = null;
          draft.tickets.byId[selectedDraftTickedId].diner_id = null;
          draft.tickets.byId[selectedDraftTickedId].diner_name = null;
        }
      })
    );

    this.selectDiner(ctx, selectedTicketId);
    this.updateDinerToLocalSeat(ctx, selectedTicketId);
    ctx.dispatch(new LoadTicketDetailSuccess(payload.ticket));
  }

  @Action(MakePayment)
  updateTicketWhenPaymentMade(
    ctx: StateContext<TicketsStateModel>,
    { payment_request, ticket }: MakePayment
  ) {
    const change_due = payment_request?.change_due,
      device_ticket_uuid = ticket?.device_ticket_uuid;

    if (!device_ticket_uuid || !change_due) {
      return;
    }

    // update it to store and send to external screen
    TicketsState.updateTicketSuccess(ctx, {
      device_ticket_uuid,
      change_due
    });
  }

  @Action(UpdateTicketSuccess)
  updateTicketSuccess(
    ctx: StateContext<TicketsStateModel>,
    { data }: UpdateTicketSuccess
  ) {
    TicketsState.updateTicketSuccess(ctx, data);
  }

  private static updateTicketSuccess(
    ctx: StateContext<TicketsStateModel>,
    data: Partial<Ticket>
  ) {
    const normalizeTickets = NormalizrHelper.normalizeTickets([<Ticket>data])
      .entities.tickets;

    ctx.setState(
      produce((draft: TicketsStateModel) => {
        const ticketUuid = data.device_ticket_uuid || draft.selectedTicket.data;
        if (normalizeTickets) {
          draft.tickets.byId[ticketUuid] = {
            ...draft.tickets.byId[ticketUuid],
            ...normalizeTickets[ticketUuid]
          };
        }
      })
    );
  }

  @Action(AddTicketItems)
  addTicketItems(
    ctx: StateContext<TicketsStateModel>,
    { ticketCalculations, ticketItemsUuids }: AddTicketItems
  ) {
    const normalizeTickets = NormalizrHelper.normalizeTickets([
      <Ticket>ticketCalculations
    ]).entities.tickets;

    ctx.setState(
      produce((draft: TicketsStateModel) => {
        const selectedTicketId = draft.selectedTicket.data;
        ticketCalculations.device_ticket_uuid = selectedTicketId;

        if (normalizeTickets) {
          draft.tickets.byId[selectedTicketId] = _.merge(
            {},
            draft.tickets.byId[selectedTicketId],
            normalizeTickets[selectedTicketId]
          );
        }
        draft.tickets.byId[selectedTicketId].ticket_items =
          ticketItemsUuids || [];
      })
    );

    const platform = this.store.selectSnapshot(
      HARDWARE_DEVICE_STATE_TOKEN
    ).platform;
    const locationType =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location.type;
    const isKiosk: boolean = locationType === LocationType.SelfServe;
    const isSmallScreen = window.innerWidth < 1199;
    if (!isKiosk && platform !== 'web' && isSmallScreen) {
      this.toastController
        .create({
          color: 'primary',
          duration: 2000,
          message: 'Menu Item added to order.',
          position: 'top'
        })
        .then((toast) => toast.present());
    }
  }

  // Removes device_ticket_item_uuid from ticket items array in a normalized ticket
  @Action(RemoveTicketItem)
  removeTicketItem(
    ctx: StateContext<TicketsStateModel>,
    { ticketItemUuids }: RemoveTicketItem
  ) {
    ctx.setState(
      produce((draft: TicketsStateModel) => {
        _.remove(
          draft.tickets.byId[draft.selectedTicket.data]?.ticket_items || [],
          (item) => ticketItemUuids.includes(item)
        );
      })
    );
  }

  @Action(RemoveTicketItemSuccess)
  removeTicketItemSuccess(
    ctx: StateContext<TicketsStateModel>,
    payload: RemoveTicketItemSuccess
  ) {
    const ticket = _.cloneDeep(payload.ticket);
    const normalizeTickets = NormalizrHelper.normalizeTickets([ticket]).entities
      .tickets;
    ctx.setState(
      produce((draft: TicketsStateModel) => {
        ticket.ticket_items = ticket.ticket_items.filter(
          (ti) => !ti.parent_uuid
        );
        const ticketUuid =
          ticket.device_ticket_uuid || draft.selectedTicket.data;
        if (normalizeTickets) {
          draft.tickets.byId[ticketUuid] = _.merge(
            {},
            draft.tickets.byId[ticketUuid],
            normalizeTickets[ticketUuid]
          );
          draft.tickets.byId[ticketUuid].ticket_items =
            normalizeTickets[ticketUuid].ticket_items;
        }
      })
    );
  }

  @Action(MakePaymentSuccess)
  makePaymentSuccess(
    ctx: StateContext<TicketsStateModel>,
    { device_ticket_uuid, payment }: MakePaymentSuccess
  ) {
    const selectedTicket = ctx.getState().tickets.byId[device_ticket_uuid];
    if (!selectedTicket) {
      return;
    }

    const location = this.store.selectSnapshot(LOCATION_STATE_TOKEN).location;
    const isCashPayment = payment.transaction.type === PaymentRequestType.CASH;
    const {
      print_receipt_for_cash_payment,
      auto_close_on_full_payment,
      print_receipt_for_non_cash
    } = location;
    const isTakeoutDeliveryTicket =
      selectedTicket.order_source === TicketSource.POS_TAKEOUT;
    const isNewBalanceZero = +payment.new_balance === 0;
    const ticketIsAutoClosed: boolean =
      auto_close_on_full_payment &&
      (payment.ticket_status === TicketStatus.CLOSED ||
        (isTakeoutDeliveryTicket && isNewBalanceZero));
    const printTicketOnFullPayment =
      auto_close_on_full_payment &&
      ((!isCashPayment && print_receipt_for_non_cash) ||
        (isCashPayment && print_receipt_for_cash_payment && isNewBalanceZero));
    const isSSK = location.type === LocationType.SelfServe;
    const actions = [];

    ctx.setState(
      produce((draft) => {
        const draftTicket = draft.tickets.byId[device_ticket_uuid];
        draftTicket.status = payment.ticket_status;
        draftTicket.outstanding_balance = parseFloat(payment.new_balance);
        draftTicket.transactions = _.compact(
          _.concat(draftTicket.transactions, payment.transaction.id)
        );
      })
    );

    // If the payment is made on SSK:
    // 1. User need to manually close the ticket
    // 2. We don't need to update the charge account balance due to the diners state has been reset (Ticket was removed)
    // 3. We don't have cash payment method.
    if (isSSK) {
      return;
    }

    if (printTicketOnFullPayment) {
      actions.push(new PrintTicket(device_ticket_uuid, false));
    }

    //Auto Close Ticket
    if (ticketIsAutoClosed) {
      ctx.patchState({ lastTicketTransaction: device_ticket_uuid });
      actions.push(new RemoveTicketSuccess(device_ticket_uuid, true));
    } else {
      /** If the ticket is auto closed when fully payment we don't need to update the charge account balance due to the diners state has been reset (Ticket was removed) */
      const state = ctx.getState();
      const ticket = state.tickets.byId[device_ticket_uuid];
      if (
        payment.transaction.type === PaymentRequestType.CHARGE_ACCOUNT &&
        ticket?.diner_id
      ) {
        actions.push(
          new UpdateChargeAccountBalance(ticket.diner_id, payment.transaction)
        );
      }
    }

    if (isCashPayment) {
      actions.push(new OpenCashDrawer());
    }

    return ctx.dispatch(actions);
  }

  // This action should not be called alone, must belong to clean the selected ticket and recall another action immediately
  @Action(CleanSelectTicket)
  cleanSelectTicket(ctx: StateContext<TicketsStateModel>) {
    ctx.setState(
      produce((draft: TicketsStateModel) => {
        draft.selectedTicket = {
          data: null,
          isProcessing: false
        };
      })
    );
  }

  @Action(GetLastQuickServiceTicket)
  getLastQuickServiceTicket(ctx: StateContext<TicketsStateModel>) {
    return this.ticketService.getTickets().pipe(
      tap((tickets) => {
        const device = this.store.selectSnapshot(APP_STATE_TOKEN).device;
        tickets = tickets.filter((i) => i.device_id === device.id) || [];
        if (tickets.length) {
          const uuid = _.last(tickets).device_ticket_uuid;
          this.ticketService
            .getTicket(uuid)
            .pipe(
              map((ticket) => ({
                ...ticket,
                ticket_items: ticket.ticket_items.map((ti) =>
                  this.addTicketItemLocalAttributes(ti, ticket.ticket_items)
                )
              }))
            )
            .subscribe((ticket) =>
              ctx.dispatch(new GetLastQuickServiceTicketSuccess(ticket, true))
            );
        } else {
          const ticket = TicketHelper.initializeTicket({
            device_ticket_uuid: GeneralHelper.generateUuid()
          });
          ctx.dispatch(new GetLastQuickServiceTicketSuccess(ticket, true));
        }
        ctx.dispatch(new UpdateMacroGridView(MacroGridView.MAIN));
      })
    );
  }

  @Action(GetLocationTickets)
  getLocationTickets({ patchState }: StateContext<TicketsStateModel>) {
    return this.ticketService.getTickets().pipe(
      tap((tickets) => {
        const device_id = this.store.selectSnapshot(APP_STATE_TOKEN).device.id;
        patchState({
          lastLocationTickets: {
            data: tickets.reduce<string[]>((prev, ticket) => {
              if (ticket.device_id === device_id) {
                prev.push(ticket.device_ticket_uuid);
              }

              return prev;
            }, []),
            isProcessing: false
          }
        });
      }),
      catchError((e: HttpErrorResponse) => {
        this.logRocketProvider.log(e.message);
        return EMPTY;
      })
    );
  }

  @Action(SelectBackQSTicket)
  selectBackQSTicket(
    ctx: StateContext<TicketsStateModel>,
    { ticketUuid }: SelectBackQSTicket
  ) {
    if (ticketUuid) {
      // get ticket object from state
      ctx.dispatch(new SelectTicket(ticketUuid));
    } else {
      const ticket = TicketHelper.initializeTicket({
        device_ticket_uuid: GeneralHelper.generateUuid()
      });
      ctx.dispatch(new GetLastQuickServiceTicketSuccess(ticket, true));
    }
    ctx.dispatch(new UpdateMacroGridView(MacroGridView.MAIN));
  }

  @Action(ToggleTicketVisibility)
  toggleTicketVisibility(
    ctx: StateContext<TicketsStateModel>,
    { isVisible }: ToggleTicketVisibility
  ) {
    const state = ctx.getState();
    ctx.patchState({
      isTicketVisible:
        isVisible !== undefined ? isVisible : !state.isTicketVisible
    });
  }

  //Dispatched if create Ticket update happens on the same table
  @Action(CreateTicketWsEvent)
  createTicketWSEvent(
    ctx: StateContext<TicketsStateModel>,
    { wsData }: CreateTicketWsEvent
  ) {
    const ticketById = ctx.getState().tickets.byId;
    const prevTicket = ticketById && ticketById[wsData.device_ticket_uuid];
    return this.ticketService.getTicket(wsData.device_ticket_uuid).pipe(
      filter((ticket) => !!ticket),
      map((ticket) =>
        !!prevTicket
          ? {
              ...ticket,
              locked: prevTicket.locked,
              locked_by_operator_id: prevTicket.locked_by_operator_id
            }
          : ticket
      ),
      tap((ticket) =>
        ctx.dispatch([
          new CreateTicketWsEventSuccess(ticket),
          new UpdateLocalSeat({
            id: wsData.seat_id,
            ticket: ticket.device_ticket_uuid,
            device_ticket_uuid: ticket.device_ticket_uuid,
            ticket_created_at: ticket.created_at,
            ticket_status: ticket.status,
            diner_id: ticket.diner_id
          })
        ])
      )
    );
  }

  //Dispatched if create Ticket update happens on the same table
  @Action(CloseTicketWsEvent)
  closeTicketWSEvent(
    ctx: StateContext<TicketsStateModel>,
    { wsData }: CloseTicketWsEvent
  ) {
    const state = ctx.getState();

    if (
      _.isNil(
        state.tickets.byId && state.tickets.byId[wsData.device_ticket_uuid]
      )
    ) {
      return;
    }

    ctx.setState(
      produce((draft: TicketsStateModel) => {
        _.remove(
          draft.tickets.allIds,
          (id) => id === wsData.device_ticket_uuid
        );
        delete draft.tickets.byId[wsData.device_ticket_uuid];
      })
    );
  }

  @Action(PrintTicket)
  printTicket(
    ctx: StateContext<TicketsStateModel>,
    { ticketUuid, open_cash_drawer }: PrintTicket
  ) {
    const submittedTicketUuid =
      ticketUuid || ctx.getState().selectedTicket.data;

    const { defaultPrinter, printers, payment_location } =
      this.store.selectSnapshot(APP_STATE_TOKEN);
    const selectedPrinter: number = AppStateHelper.getDefaultPrinter(
      defaultPrinter,
      printers,
      payment_location?.default_printer_id
    );

    if (selectedPrinter && submittedTicketUuid) {
      return this.ticketService.printTicket(
        submittedTicketUuid,
        selectedPrinter,
        open_cash_drawer
      );
    }
  }

  @Action(PrintLastReceipt)
  printLastReceipt(ctx: StateContext<TicketsStateModel>) {
    const state = ctx.getState();
    const lastTicketTransaction = state.lastTicketTransaction;
    ctx.dispatch(new PrintTicket(lastTicketTransaction));
  }

  //Call the API to lock the current selected ticket
  @Action(LockTicket)
  lockTicket(ctx: StateContext<TicketsStateModel>) {
    const locationType =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location.type;
    const isDiningRoom: boolean = locationType === LocationType.DiningRoom;
    const isTakeoutDelivery = this.store.selectSnapshot(
      TAKEOUT_DELIVERY_STATE_TOKEN
    ).enableTakeOutDelivery;

    if (!isDiningRoom || isTakeoutDelivery) {
      return;
    }

    const state = ctx.getState();
    const selectedTicketId = state.selectedTicket.data;
    const operator = this.store.selectSnapshot(APP_STATE_TOKEN).operator;
    const ticket = state.tickets.byId && state.tickets.byId[selectedTicketId];
    ctx.dispatch([
      new LockOrUnlockTicketSuccess(selectedTicketId, true, operator.id),
      new UpdateLocalSeat({
        id: ticket?.seat_id,
        ticket_locked: true,
        ticket_locked_by_operator_id: operator.id
      })
    ]);
    return this.ticketService.lockTicket(selectedTicketId, operator.id);
  }

  //Call the API to unlock the current selected ticket
  @Action(UnlockTicket)
  unlockTicket(
    ctx: StateContext<TicketsStateModel>,
    { device_ticket_uuid }: UnlockTicket
  ) {
    const locationType =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location.type;
    const isDiningRoom: boolean = locationType === LocationType.DiningRoom;

    if (!isDiningRoom) {
      return;
    }

    const state = ctx.getState();
    const ticket = state.tickets.byId[device_ticket_uuid];
    ctx.dispatch([
      new LockOrUnlockTicketSuccess(device_ticket_uuid, null, null),
      new UpdateLocalSeat({
        id: ticket.seat_id,
        ticket_locked: false,
        ticket_locked_by_operator_id: null
      })
    ]);
    return this.ticketService.unlockTicket(device_ticket_uuid);
  }

  @Action(LockOrUnlockTicketSuccess)
  lockOrUnlockTicketSuccess(
    ctx: StateContext<TicketsStateModel>,
    {
      ticketUuid,
      locked,
      locked_by_operator_id,
      wsNotified
    }: LockOrUnlockTicketSuccess
  ) {
    ctx.setState(
      produce((draft: TicketsStateModel) => {
        if (draft.tickets.byId && draft.tickets.byId[ticketUuid]) {
          draft.tickets.byId[ticketUuid].locked = locked;
          draft.tickets.byId[ticketUuid].locked_by_operator_id =
            locked_by_operator_id;
        } else {
          draft.tickets.byId = {
            ...draft.tickets.byId,
            [ticketUuid]: <NormalizedTicket>{ locked, locked_by_operator_id }
          };
        }
      })
    );

    if (!locked && wsNotified) {
      return ctx.dispatch(new LoadTicketDetail(ticketUuid));
    }
  }

  @Action(AddTaxExemption)
  addTaxExemption(ctx: StateContext<TicketsStateModel>) {
    const state = ctx.getState();
    return this.ticketService
      .addTaxExemption(state.selectedTicket.data)
      .pipe(tap((ticket) => ctx.dispatch(new UpdateTicketSuccess(ticket))));
  }

  @Action(RemoveTaxExemption)
  removeTaxExemption(ctx: StateContext<TicketsStateModel>) {
    const state = ctx.getState();
    const selectedTicketId = state.selectedTicket.data;

    if (state.tickets.byId[selectedTicketId].tax_exempt) {
      return this.ticketService
        .removeTaxExemption(selectedTicketId)
        .pipe(tap((ticket) => ctx.dispatch(new UpdateTicketSuccess(ticket))));
    }
  }

  @Action([ChangeTicketMeal, TemporarySeatMealChange])
  setTicketIsChangingMeal(ctx: StateContext<TicketsStateModel>) {
    this._setTicketProcessing(ctx, true);
  }

  @Action([ChangeTicketMealSuccess, TemporarySeatMealChangeSuccess])
  setTicketChangeMealSuccess(ctx: StateContext<TicketsStateModel>) {
    this._setTicketProcessing(ctx, false);
  }

  //1: Cancel or Void Ticket based on status of old Ticket
  //2: Re-select seat and Ticket, currently UI does not go back to seat after removing original Ticket
  //3: Re-add meal plan if it was present on the old Ticket
  //4: Validate QS, no need to re-select seat on there
  @Action(ChangeTicketMeal)
  changeTicketMeal(
    ctx: StateContext<TicketsStateModel>,
    payload: ChangeTicketMeal
  ) {
    const state = ctx.getState();
    const prevTicketUuid = state.selectedTicket.data;
    const {
      id,
      seat_id,
      table_id,
      delivery_note,
      delivery_type,
      diner_id,
      guest_of_diner_id,
      meal_plan
    } = state.tickets.byId[prevTicketUuid];
    const ticketDinerId = diner_id || guest_of_diner_id;
    const meal_id = payload.meal;
    const changeTicketMealApiActions$ = (
      id ? this.ticketService.deleteTicket(prevTicketUuid) : of()
    ).pipe(
      switchMap(() =>
        this.ticketService.createTicket({
          meal_id,
          seat_id,
          table_id,
          delivery_note,
          delivery_type
        })
      )
    );
    const assignDinerToTicket = (ticket: Ticket) =>
      diner_id
        ? this.ticketService
            .assignDinerToTicket(diner_id, ticket.device_ticket_uuid)
            .pipe(
              map(() => ({
                ...ticket,
                diner_id
              }))
            )
        : this.ticketService
            .updateTicket({
              guest_of_diner_id,
              device_ticket_uuid: ticket.device_ticket_uuid
            })
            .pipe(map((res) => res));

    const typeFn: UnaryFunction<
      Observable<Ticket>,
      Observable<Ticket>
    > = ticketDinerId
      ? pipe(
          switchMap((res) => assignDinerToTicket(res)),
          switchMap((res) =>
            meal_plan
              ? this._assignMealPlanToTicket(
                  res.device_ticket_uuid,
                  meal_plan.id
                )
              : of(res)
          ),
          tap((result) => ctx.dispatch(new ChangeTicketMealSuccess(result)))
        )
      : pipe(
          tap((result) => ctx.dispatch(new ChangeTicketMealSuccess(result)))
        );

    return changeTicketMealApiActions$.pipe(typeFn);
  }

  @Action(ChangeTicketMealSuccess)
  changeTicketMealSuccess(
    ctx: StateContext<TicketsStateModel>,
    payload: ChangeTicketMealSuccess
  ) {
    const state = ctx.getState();
    const prevTicketUuid = state.selectedTicket.data;
    const prevTicket = state.tickets.byId[prevTicketUuid];

    ctx.setState(
      produce((draft: TicketsStateModel) => {
        const entities = NormalizrHelper.normalizeTickets([
          payload.newTicket
        ]).entities;

        const operatorId =
          this.store.selectSnapshot(APP_STATE_TOKEN).operator.id;

        delete draft.tickets.byId[draft.selectedTicket.data];
        _.remove(draft.tickets.allIds, draft.selectedTicket.data);
        draft.selectedTicket.data = payload.newTicket.device_ticket_uuid;
        draft.tickets.allIds.push(payload.newTicket.device_ticket_uuid);
        draft.tickets.byId = {
          ...draft.tickets.byId,
          ...entities.tickets
        };
        const draftTicketId = draft.selectedTicket.data;
        draft.tickets.byId[draftTicketId] = {
          ...draft.tickets.byId[draftTicketId],
          locked: true,
          locked_by_operator_id: operatorId
        };

        if (prevTicket.diner_id) {
          draft.tickets.byId[draftTicketId].diner = prevTicket.diner;
          draft.tickets.byId[draftTicketId].diner_id = prevTicket.diner_id;
          draft.tickets.byId[draftTicketId].diner_name = prevTicket.diner_name;
        }
      })
    );
  }

  @Action(SetupTakeoutOrder)
  async setupTakeoutOrder(
    ctx: StateContext<TicketsStateModel>,
    { takeoutOrderSetup }: SetupTakeoutOrder
  ) {
    const locationType =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location.type;
    const isDiningRoom: boolean = locationType === LocationType.DiningRoom;
    const isQuickService: boolean = locationType === LocationType.QuickService;

    if (!isDiningRoom && !isQuickService) {
      return ctx.dispatch(
        new SetupTakeoutOrderError(
          'Current Location Type does not support Takeout Orders'
        )
      );
    }

    const state = ctx.getState();
    const actions = [];

    const selectedTicket =
      state.tickets.byId && state.tickets.byId[state.selectedTicket.data];

    //Setup for update ticket
    const takeoutOrderTicket: Partial<Ticket> = {
      ticket_date: takeoutOrderSetup.date,
      order_source: TicketSource.POS_TAKEOUT,
      delivery_type:
        takeoutOrderSetup.orderType === DeliveryType.DELIVERY
          ? DeliveryType.DELIVERY
          : DeliveryType.PICK_UP,
      meal_id: takeoutOrderSetup.mealId
    };
    const deliveryAreaTiming = takeoutOrderSetup.mealDeliveryAreaTiming;
    if (deliveryAreaTiming) {
      // ASAP tickets have a undefined meal_delivery_area_timing_id
      takeoutOrderTicket.meal_delivery_area_timing_id =
        deliveryAreaTiming.toString() === ' ASAP '
          ? undefined
          : deliveryAreaTiming;
    }

    //If it's quick service with a valid selectedTicket - update - otherwise create a new ticket
    if (isQuickService && !!selectedTicket?.status) {
      actions.push(new UpdateTicket(takeoutOrderTicket));
    } else {
      takeoutOrderTicket.meal_id = takeoutOrderSetup.mealId;
      actions.push(new CreateTicket(takeoutOrderTicket));
    }

    actions.push(
      new UpdateSelectedMealId(
        takeoutOrderSetup.mealId,
        this.formatDate(parseISO(takeoutOrderSetup.date))
      ),
      new UpdateMacroGridView(MacroGridView.MAIN),
      new SetupTakeoutOrderSuccess()
    );

    return ctx.dispatch(actions);
  }

  @Action(CreateDraftTicket)
  createDraftTicket(ctx: StateContext<TicketsStateModel>) {
    const draftTicket = TicketHelper.initializeTicket({
      device_ticket_uuid: GeneralHelper.generateUuid()
    });
    const entities = NormalizrHelper.normalizeTickets([draftTicket]).entities;
    this.addTicketToState(ctx, draftTicket, true, entities);
  }

  private formatDate(date: number | Date, formatString: string = 'yyyy-MM-dd') {
    return format(date, formatString);
  }

  private addTicketItemLocalAttributes(
    ticketItem: TicketItem,
    ticketItems: TicketItem[]
  ): TicketItem {
    ticketItem.local_attributes = {
      removed: false
    };
    if (!ticketItem?.parent_uuid) {
      ticketItem.ticket_items = ticketItems.filter(
        (i) => i.parent_uuid === ticketItem.device_ticket_item_uuid
      );
    }
    return ticketItem;
  }

  private selectDiner(
    ctx: StateContext<TicketsStateModel>,
    ticketUuid: string,
    addMealPlan = false,
    getDinerDetail = false,
    forceApplyMealPlan = false
  ) {
    const state = ctx.getState();
    if (state.tickets.allIds.indexOf(ticketUuid) !== -1) {
      ctx.dispatch(
        new SelectDiner(
          state.tickets.byId[ticketUuid],
          addMealPlan,
          getDinerDetail,
          forceApplyMealPlan
        )
      );
    }
  }

  private updateDinerToLocalSeat(
    ctx: StateContext<TicketsStateModel>,
    ticketUuid: string
  ) {
    const state = ctx.getState();
    if (state.tickets.allIds.indexOf(ticketUuid) !== -1) {
      const ticket = state.tickets.byId[ticketUuid];
      const diner = ticket?.diner_id || null;
      ctx.dispatch(
        new UpdateLocalSeat({
          id: ticket?.seat_id,
          diner_id: diner,
          tableId: ticket.table_id
        })
      );
    }
  }

  private updateMenuForTicket(
    ctx: StateContext<TicketsStateModel>,
    date: string,
    ticketId: string,
    actions?: any[]
  ) {
    const state = ctx.getState();
    const menuSelectedMealId =
      this.store.selectSnapshot(MENU_STATE_TOKEN).selected_meal_id;
    const nextTicket = state.tickets.byId && state.tickets.byId[ticketId];
    const ticketMealId = nextTicket?.meal_id;
    if (!ticketMealId || ticketMealId === menuSelectedMealId) {
      return;
    }

    if (actions) {
      actions.push(new UpdateSelectedMealId(nextTicket.meal_id, date));
      return;
    }

    return ctx.dispatch(new UpdateSelectedMealId(nextTicket.meal_id, date));
  }

  private addTicketToState(
    ctx: StateContext<TicketsStateModel>,
    ticket: Ticket,
    selectTicket: boolean = false,
    entities: Pick<
      NormalizedEntities,
      'tickets' | 'ticket_items' | 'transactions' | 'diners'
    >
  ) {
    ctx.setState(
      produce((draft: TicketsStateModel) => {
        if (entities.tickets) {
          draft.tickets = {
            byId: { ...draft.tickets.byId, ...entities.tickets },
            allIds: _.union(draft.tickets.allIds, Object.keys(entities.tickets))
          };
        }
        if (selectTicket) {
          draft.selectedTicket.data = ticket.device_ticket_uuid;
        }
      })
    );
  }

  @Action(RemoveAllSskTicketItems)
  removeAllSskTicketItems(
    ctx: StateContext<TicketsStateModel>,
    payload: RemoveAllSskTicketItems
  ) {
    const removedTicketItemUuids = _.flatMapDeep(
      payload.ticketItems.map((ti) =>
        ti.items.map((i) => i.device_ticket_item_uuid)
      )
    );

    return forkJoin(
      removedTicketItemUuids.map((uuid) =>
        this.ticketService.cancelTicketItem(uuid)
      )
    ).pipe(
      switchMap((result) =>
        ctx.dispatch(
          new RemoveAllSskTicketItemsSuccess(
            _.minBy(result, (ticket) => +(ticket?.outstanding_balance || 0))
          )
        )
      ),
      catchError((error) =>
        ctx.dispatch(new RemoveAllSskTicketItemsError(error))
      )
    );
  }

  @Action(RemoveAllSskTicketItemsSuccess)
  removeAllSskTicketItemsSuccess(
    ctx: StateContext<TicketsStateModel>,
    payload: RemoveAllSskTicketItemsSuccess
  ) {
    const state = ctx.getState();
    const selectedTicketId = state.selectedTicket.data;
    const selectedTicket = state.tickets.byId[selectedTicketId];
    const updateTicket = NormalizrHelper.normalizeTickets([payload.ticket])
      .entities.tickets[selectedTicketId];

    ctx.setState(
      produce((draft: TicketsStateModel) => {
        draft.tickets.byId[selectedTicketId] = {
          ...selectedTicket,
          ...updateTicket,
          ticket_items: []
        };
      })
    );
  }

  @Action(SelectNewSeat)
  selectNewSeat(ctx: StateContext<TicketsStateModel>, payload: SelectNewSeat) {
    const operatorId = this.store.selectSnapshot(APP_STATE_TOKEN).operator.id;
    const seatState = this.store.selectSnapshot(SEATS_STATE_TOKEN);
    const selectedSeat = seatState.seats.byId[payload.seatId];
    const prevSeatId = seatState.selectedSeat;

    if (prevSeatId) {
      const prevSeat = seatState.seats.byId[prevSeatId];
      const ticketsState = this.store.selectSnapshot(TICKETS_STATE_TOKEN);
      if (
        ticketsState.tickets.byId &&
        ticketsState.tickets.byId[prevSeat.device_ticket_uuid]
      ) {
        ctx.setState(
          produce((draft: TicketsStateModel) => {
            draft.tickets.byId[prevSeat.device_ticket_uuid].locked = false;
            draft.tickets.byId[
              prevSeat.device_ticket_uuid
            ].locked_by_operator_id = null;
          })
        );
      }
    }

    if (selectedSeat.device_ticket_uuid) {
      ctx.setState(
        produce((draft: TicketsStateModel) => {
          const deviceTicketUuid = selectedSeat.device_ticket_uuid,
            draftTicket =
              draft.tickets.byId && draft.tickets.byId[deviceTicketUuid];

          draft.selectedTicket.data = deviceTicketUuid;

          if (draftTicket) {
            draftTicket.locked = true;
            draftTicket.locked_by_operator_id = operatorId;
          }
        })
      );

      const currentState = ctx.getState();
      const selectedTicket =
        currentState.tickets.byId[selectedSeat.device_ticket_uuid];
      const ticketMealId = selectedTicket?.meal_id;
      ctx.dispatch(
        new UpdateSelectedMealId(ticketMealId, selectedTicket?.ticket_date)
      );
    } else {
      ctx.patchState({});
      return this.ticketService.createTicket({ seat_id: payload.seatId }).pipe(
        filter((ticket) => !!ticket),
        tap((ticket) =>
          ctx.dispatch(
            _.compact([
              new CreateTicketForSeatSuccess(ticket),
              new UpdateSelectedMealId(ticket.meal_id, ticket.ticket_date)
            ])
          )
        )
      );
    }
  }

  @Action(CreateTicketForSeatSuccess)
  createTicketForSeatSuccess(
    ctx: StateContext<TicketsStateModel>,
    payload: CreateTicketForSeatSuccess
  ) {
    const operatorId = this.store.selectSnapshot(APP_STATE_TOKEN).operator.id;

    ctx.setState(
      produce((draft: TicketsStateModel) => {
        // selectedTicket: payload.ticket.device_ticket_uuid
        draft.selectedTicket.data = payload.ticket.device_ticket_uuid;
        const ticketEntities = NormalizrHelper.normalizeTickets([
          payload.ticket
        ]).entities;
        draft.tickets = {
          allIds: _.union(draft.tickets.allIds, [
            payload.ticket.device_ticket_uuid
          ]),
          byId: { ...draft.tickets.byId, ...ticketEntities.tickets }
        };
        draft.tickets.byId[draft.selectedTicket.data].locked = true;
        draft.tickets.byId[draft.selectedTicket.data].locked_by_operator_id =
          operatorId;
      })
    );

    return merge([
      this.ticketService.lockTicket(
        payload.ticket.device_ticket_uuid,
        operatorId
      ),
      ctx.dispatch(new SetSelectedMealId(payload.ticket.meal_id))
    ]);
  }

  @Action(UnselectSeat)
  unselectSeat(ctx: StateContext<TicketsStateModel>) {
    const state = ctx.getState();
    const ticketUuid = state.selectedTicket.data;

    ctx.setState(
      patch({
        selectedTicket: {
          data: null,
          isProcessing: false
        }
      })
    );

    if (!state.tickets.byId || !state.tickets.byId[ticketUuid]) {
      return;
    }

    ctx.setState(
      produce((draft: TicketsStateModel) => {
        draft.tickets.byId[ticketUuid].locked = false;
        draft.tickets.byId[ticketUuid].locked_by_operator_id = null;
      })
    );
    return this.ticketService.unlockTicket(ticketUuid);
  }

  @Action(TicketClosedWsEvent)
  ticketClosedWsEvent(
    ctx: StateContext<TicketsStateModel>,
    payload: TicketClosedWsEvent
  ) {
    this.removeTicketSuccessHandler(ctx, {
      device_ticket_uuid: payload.wsData.device_ticket_uuid,
      removeTicket: true
    });
  }

  private removeTicketSuccessHandler(
    ctx: StateContext<TicketsStateModel>,
    payload: { device_ticket_uuid: string; removeTicket: boolean }
  ) {
    const { device_ticket_uuid, removeTicket } = payload;
    const state = ctx.getState();
    const ticket = state.tickets.byId && state.tickets.byId[device_ticket_uuid];

    if (!ticket) {
      return;
    }

    const location = this.store.selectSnapshot(LOCATION_STATE_TOKEN).location;
    const locationType = location.type;
    const isDiningRoom: boolean = locationType === LocationType.DiningRoom;
    const isQuickService: boolean = locationType === LocationType.QuickService;
    const isTakeoutDelivery: boolean =
      ticket.order_source === TicketSource.POS_TAKEOUT;
    let isRemoved = false;

    const removeTicketAction = () => {
      ctx.setState(
        produce((draft: TicketsStateModel) => {
          //Remove ticket if it exists locally - Ticket will not exist locally if one user is on the floor plan and the other user is working on a table
          if (_.has(draft.tickets.byId, device_ticket_uuid)) {
            isRemoved = true;
            delete draft.tickets.byId[device_ticket_uuid];
            _.remove(
              draft.tickets.allIds,
              (item) => item === device_ticket_uuid
            );
          }

          if (!device_ticket_uuid) {
            draft.selectedTicket = {
              data: null,
              isProcessing: false
            };
          }
        })
      );

      if (isDiningRoom && !isTakeoutDelivery) {
        ctx.dispatch(new SelectSeat(ticket?.seat_id, true));
      }
    };

    //Unselect the seat if in dining room
    if (isDiningRoom) {
      if (isTakeoutDelivery) {
        removeTicketAction();
        ctx.dispatch(new SelectTicket());
      } else {
        const seatId = ticket?.seat_id;
        const table_id = ticket?.table_id;
        const localSeat = {
          id: seatId,
          table_id,
          device_ticket_uuid: undefined,
          floorplan_status: undefined,
          ticket: undefined,
          ticket_created_at: undefined,
          ticket_status: undefined,
          ticket_locked: false,
          ticket_locked_by_operator_id: undefined,
          diner_id: undefined
        };

        ctx.dispatch(new UpdateLocalSeat(localSeat));
        const isClosedTicket = ticket?.status === TicketStatus.CLOSED;

        if (isClosedTicket || removeTicket) {
          removeTicketAction();
          if (!isRemoved) {
            return;
          }
          ctx.dispatch(new SelectSeat());
          return;
        }
      }
    } else if (isQuickService) {
      removeTicketAction();
      const isQuickServicePage = this.router.url.includes(
        PageConstant.QUICK_SERVICE_PAGE
      );
      const isTakeOutDeliveryPage = this.router.url.includes(
        PageConstant.TAKEOUT_DELIVERY_PAGE(isDiningRoom)
      );

      ctx.dispatch(
        new StateReset(DinersState, TicketItemsState, TransactionsState)
      );

      const actions = [];

      if (isQuickServicePage) {
        const newTicket = TicketHelper.initializeTicket({
          device_ticket_uuid: GeneralHelper.generateUuid()
        });
        actions.push(new GetLastQuickServiceTicketSuccess(newTicket, true));
        // this.navController.back();
      } else {
        actions.push(
          //DEF-4575: Ensure that the header displays the correct buttons and the ticket is reset
          isTakeOutDeliveryPage ? new ExitTakeOutDelivery() : new SelectTicket()
        );
      }
      return ctx.dispatch(actions);
    }

    return ctx.dispatch([
      new StateReset(DinersState, TicketItemsState, TransactionsState),
      new StateOverwrite([
        TicketsState,
        {
          ...DEFAULT_TICKET_STATE,
          lastTicketTransaction: ctx.getState().lastTicketTransaction
        }
      ])
    ]);
  }

  @Action(TemporarySeatMealChange)
  temporarySeatMealChange(
    ctx: StateContext<TicketsStateModel>,
    { meal_id }: TemporarySeatMealChange
  ) {
    const state = ctx.getState();
    const selectedTicketId = state.selectedTicket.data;
    const {
      diner_id,
      guest_of_diner_id,
      meal_plan,
      table_id,
      delivery_note,
      delivery_type
    } = state.tickets.byId[selectedTicketId];
    const assignedMealPlanId = meal_plan?.id;
    const deleteTicket = () =>
      this.ticketService
        .deleteTicket(selectedTicketId)
        .pipe(switchMap(() => EMPTY));

    const assignDinerToTicket = (_ticket: Ticket) =>
      diner_id
        ? this.ticketService
            .assignDinerToTicket(diner_id, _ticket.device_ticket_uuid)
            .pipe(
              map(() => ({
                ..._ticket,
                diner_id
              }))
            )
        : this.ticketService.updateTicket({
            device_ticket_uuid: _ticket.device_ticket_uuid,
            guest_of_diner_id
          });
    const assignMealPlanToTicket = (_ticket: Ticket) =>
      assignedMealPlanId
        ? this._assignMealPlanToTicket(
            _ticket.device_ticket_uuid,
            assignedMealPlanId
          )
        : of(_ticket);

    return concat(
      deleteTicket(),
      this.diningService.createTemporarySeat(table_id).pipe(
        switchMap((newSeat) =>
          this.ticketService
            .createTicket({
              meal_id,
              table_id,
              delivery_note,
              delivery_type,
              seat_id: newSeat.id
            })
            .pipe(
              switchMap((newTicket) =>
                (diner_id || guest_of_diner_id
                  ? assignDinerToTicket(newTicket).pipe(
                      switchMap(() => assignMealPlanToTicket(newTicket))
                    )
                  : of(newTicket)
                ).pipe(map((ticket) => ({ ticket, seat: newSeat })))
              ),
              tap(({ ticket, seat }) =>
                ctx.dispatch(new TemporarySeatMealChangeSuccess(seat, ticket))
              )
            )
        )
      )
    );
  }

  @Action(TemporarySeatMealChangeSuccess)
  temporarySeatMealChangeSuccess(
    ctx: StateContext<TicketsStateModel>,
    payload: TemporarySeatMealChangeSuccess
  ) {
    const state = ctx.getState();
    const prevTicket: NormalizedTicket =
      state.tickets.byId[state.selectedTicket.data];

    ctx.setState(
      produce((draft: TicketsStateModel) => {
        const ticketEntities = NormalizrHelper.normalizeTickets([
          payload.ticket
        ]).entities.tickets;

        draft.tickets.allIds = draft.tickets.allIds.splice(
          draft.tickets.allIds.indexOf(draft.selectedTicket.data),
          1,
          payload.ticket.device_ticket_uuid
        );
        delete draft.tickets.byId[draft.selectedTicket.data];
        draft.tickets.byId = {
          ...draft.tickets.byId,
          ...ticketEntities
        };

        draft.selectedTicket.data = payload.ticket.device_ticket_uuid;
        draft.tickets.byId[draft.selectedTicket.data] = {
          ...draft.tickets.byId[draft.selectedTicket.data],
          diner_id: prevTicket.diner_id,
          meal_plan: prevTicket.meal_plan,
          meal_plan_id: prevTicket.meal_plan_id
        };
      })
    );
  }

  @Action(TicketLockedChangedFromWS)
  ticketLockedChangedFromWS(
    ctx: StateContext<TicketsStateModel>,
    payload: TicketLockedChangedFromWS
  ) {
    const selectedSeatId =
      this.store.selectSnapshot(SEATS_STATE_TOKEN).selectedSeat;
    const isCurrentSeatChanged =
      !!selectedSeatId && selectedSeatId === payload.data.seat_id;
    const operatorId = this.store.selectSnapshot(APP_STATE_TOKEN).operator.id;

    if (!isCurrentSeatChanged) {
      return ctx.dispatch(
        new LockOrUnlockTicketSuccess(
          payload.data.device_ticket_uuid,
          payload.data.locked,
          payload.data.locked_by_operator_id,
          true
        )
      );
    }

    if (isCurrentSeatChanged) {
      return this.ticketService.lockTicket(
        payload.data.device_ticket_uuid,
        operatorId
      );
    }
  }

  @Action(CancelServicesTicket)
  cancelServicesTicket(
    ctx: StateContext<TicketsStateModel>,
    payload: CancelServicesTicket
  ) {
    ctx.setState(
      produce((draft: TicketsStateModel) => {
        const canceledTicket = draft.tickets.byId[payload.device_ticket_uuid];
        if (canceledTicket) {
          delete draft.tickets.byId[payload.device_ticket_uuid];
          _.remove(draft.tickets.allIds, payload.device_ticket_uuid);
          draft.selectedTicket.data = null;
        }
      })
    );
  }

  @Action(SetTicketCreatingStatus)
  setTicketCreatingStatus(
    ctx: StateContext<TicketsStateModel>,
    payload: SetTicketCreatingStatus
  ) {
    ctx.patchState({
      isTicketCreating: payload.isCreating
    });
  }

  @Action(FireTicketAndClose)
  fireTicketAndClose(
    ctx: StateContext<TicketsStateModel>,
    { deviceTicketUuid, paymentTypes }: FireTicketAndClose
  ) {
    const selectedTicketState = ctx.getState().selectedTicket,
      isSelectedTicketValid = selectedTicketState.data === deviceTicketUuid,
      isSelectedTicketProcessing = selectedTicketState.isProcessing;

    if (isSelectedTicketProcessing) {
      return;
    }

    if (isSelectedTicketValid) {
      this._setTicketProcessing(ctx, true);
    }

    //Action will only fire a ticket, used in Self Service
    return this.ticketService
      .fireTicket(
        deviceTicketUuid,
        this.store.selectSnapshot(APP_STATE_TOKEN).operator.id
      )
      .pipe(
        switchMap(() =>
          ctx.dispatch(new CloseTicket(deviceTicketUuid, paymentTypes))
        ),
        catchError(() =>
          ctx.dispatch(
            new FireTicketAndCloseError(
              'There was a problem firing your order. Please try again.'
            )
          )
        )
      );
  }

  @Action(FireTicketAndCloseError)
  fireTicketAndCloseError(ctx: StateContext<TicketsStateModel>) {
    this.modalService.closeLoadingModal();
    this._setTicketProcessing(ctx, false);
  }

  @Action(AssignGuestMealPlan)
  assignGuestMealPlan(
    ctx: StateContext<TicketsStateModel>,
    { diner_id }: AssignGuestMealPlan
  ) {
    return this.ticketService
      .assignGuestMealPlan(diner_id)
      .pipe(tap((ticket) => TicketsState.updateTicketSuccess(ctx, ticket)));
  }

  private _setTicketProcessing(
    ctx: StateContext<TicketsStateModel>,
    isProcessing: boolean
  ) {
    ctx.setState(
      patch({
        selectedTicket: patch({
          isProcessing
        })
      })
    );
  }

  private _assignMealPlanToTicket(
    ticket_uuid: string,
    pos_mealplan_id: number
  ): Observable<Ticket> {
    return this.ticketService.addMealPlan(ticket_uuid, pos_mealplan_id).pipe(
      catchError((error: HttpErrorResponse) => {
        this.modalService.alertErrorMessage(
          error.error.message || 'Unable to assign the meal plan.'
        );
        return EMPTY;
      })
    );
  }
}
