import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Observable, filter, map } from 'rxjs';
import _ from 'lodash';

import { PageConstant } from 'src/app/constants';
import { Operator } from 'src/app/models';
import { NavigationItem } from 'src/app/pages/pos-manager/main/main.page';
import { PosManagerBackToPos } from 'src/app/store/pos-manager';
import { AppSelectors } from 'src/app/store/app/app.selectors';
import { PosManagerService } from 'src/app/services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'pos-manager-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManagerMenuComponent implements OnInit, OnDestroy {
  @Select(AppSelectors.getOperator)
  private readonly _staff$!: Observable<Operator>;

  readonly pageConstant = PageConstant;

  private readonly _navigationItems: Partial<NavigationItem>[] = [
    {
      icon: '../../assets/icon/icon-items-management.svg',
      name: 'POS Item Management',
      routerLink: '/' + this.pageConstant.POS_MANAGER_POS_ITEMS_MANAGEMENT_PAGE
    },
    {
      icon: '../../assets/icon/icon-modifierprompts.svg',
      name: 'Modifier Prompts',
      routerLink: '/' + this.pageConstant.POS_MANAGER_MODIFIER_PROMPTS_PAGE
    },
    {
      icon: '../../assets/icon/icon-table-management.svg',
      name: 'Table Management',
      routerLink: '/' + this.pageConstant.POS_MANAGER_TABLES_MANAGEMENT_PAGE
    },
    {
      icon: '../../assets/icon/icon-operator.svg',
      name: 'Operators',
      routerLink:
        '/' + this.pageConstant.POS_MANAGER_POS_OPERATORS_MANAGEMENT_PAGE
    },
    {
      permissionKey: 'schedules_and_services',
      icon: '../../assets/icon/icon-schedule-service.svg',
      name: 'Schedules & Services',
      routerLink: '/' + this.pageConstant.POS_MANAGER_SCHEDULES_SERVICES_PAGE
    }
  ];

  readonly filterNavigationItems$ = this._staff$.pipe(
    filter((staff) => !!staff),
    map((staff) =>
      this._navigationItems.filter((item) =>
        _.isNil(item.permissionKey)
          ? true
          : staff.permissions[item.permissionKey]
      )
    )
  );

  constructor(
    private readonly menuController: MenuController,
    private readonly store: Store,
    private readonly navController: NavController,
    private readonly posManagerService: PosManagerService,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.posManagerService.canAccessReportingPage) {
      this._navigationItems.push({
        icon: '../../assets/icon/file-chart-pie.svg',
        name: 'Reporting',
        routerLink: '/' + PageConstant.POS_MANAGER_REPORTING_PAGE
      });
    }
  }

  ngOnDestroy(): void {
    this.menuController
      .isOpen()
      .then((isOpen) => isOpen && this.menuController.close());
  }

  onGoTo(url: string) {
    this.navController.navigateRoot(url);
  }

  close() {
    this.menuController.close();
  }

  goToMenuItems() {
    this.navController.navigateRoot(
      PageConstant.POS_MANAGER_POS_ITEMS_MANAGEMENT_PAGE
    );
  }

  goToModifierPrompts() {
    this.navController.navigateRoot(
      PageConstant.POS_MANAGER_MODIFIER_PROMPTS_PAGE
    );
  }

  async backToPos() {
    await this.menuController.close();
    this.store.dispatch(new PosManagerBackToPos(this.route));
  }
}
