import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SskSelectOrderTypeComponent } from './ssk-note-modal.component';
import { IonicModule } from '@ionic/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
//---------------------------------------------------------------------------------
import { InputFocusDirectiveModule } from 'src/app/directives/input-focus-directive/input-focus-directive.module';

@NgModule({
  declarations: [SskSelectOrderTypeComponent],
  imports: [
    CommonModule,
    IonicModule,
    FontAwesomeModule,
    InputFocusDirectiveModule
  ]
})
export class SskNoteModalModule {}
