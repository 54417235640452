import { Injectable } from '@angular/core';
import {
  Action,
  Actions,
  State,
  StateContext,
  Store,
  ofActionCompleted
} from '@ngxs/store';
import { catchError, map, of, switchMap, take, tap } from 'rxjs';
import { StateOverwrite, StateReset, StateResetAll } from 'ngxs-reset-plugin';
import { Router } from '@angular/router';
import { format } from 'date-fns';
//----------------------------------------------
import { DinerService, ModalService, TicketService } from 'src/app/services';
import {
  AddDinerToTicket,
  CancelTicket,
  CloseTicket,
  CloseTicketSuccess,
  CreateTicketSuccess,
  FireTicketAndClose,
  RemoveTicketItemSuccess,
  UpdateTicketSuccess,
  VoidTicket
} from '../tickets/tickets.action';
import { StartIdleTime, StopIdle } from '../app/app.action';
import {
  SskGetDinerByBarcode,
  SskGetDinerByBarcodeFailed,
  StartOver,
  ResetSelfServiceState,
  Initialize,
  GetDinerByBarcodeError,
  MenuChangeEventFromWs,
  CancelOrder,
  FocusOnCategoryInCategoryList,
  FocusOnMenuItem,
  ViewAllItems,
  ResetFocusState,
  SelectOrderType,
  SetDeliveryNote,
  AddDeliveryFeeToTicket,
  RemoveDeliveryFeeFromTicket
} from './self-service.action';
import {
  LOCATION_STATE_TOKEN,
  LocationState
} from '../location/location.state';
import { AppState } from '../app/app.state';
import { GetMacroGridDetailSuccess, GetMenuSuccess } from '../menu/menu.action';
import {
  DeliveryType,
  ErrorCodeKey,
  LocationType,
  MacroGridView,
  MenuCategory,
  MenuItem,
  PosLocation,
  SelfServeSettings,
  TicketStatus
} from 'src/app/models';
import { BaseProvider, WebSocketProvider } from 'src/app/providers';
import { CommonConstant, PageConstant } from 'src/app/constants';
import { MealHelper } from 'src/app/helpers';
import { UpdateSelectedMealId } from '../location/location.action';
import { MenuState } from '../menu/menu.state';
import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';
import { environment } from 'src/environments/environment';
import {
  DEFAULT_TICKET_STATE,
  TICKETS_STATE_TOKEN,
  TicketsState
} from 'src/app/store/tickets/tickets.state';
import { HardwareDeviceState } from 'src/app/store/hardware-device/hardware-device.state';
import { TRANSACTIONS_STATE_TOKEN } from 'src/app/store/transactions/transactions.state';
import { MENU_STATE_TOKEN } from 'src/app/store/menu/menu.state.model';
import { LocationStateHelper } from 'src/app/store/location/location.state.helper';
import { TicketHelper } from 'src/app/helpers/ticket.helper';
import { AddTicketItem } from 'src/app/store/ticket-items/ticket-items.action';
import { SskHelper } from 'src/app/helpers/ssk.helper';

export interface SelfServiceStateModel {
  isSetupComplete: boolean;
  isMenuLoaded: boolean;
  isMacroGridLoaded: boolean;
  isMenuWSConnected: boolean;
  addingDinerToTicket: boolean;
  focusedCategory: MenuCategory;
  focusedMenuItem: MenuItem;
  // selectedOrderType?: SskOrderType;
  // deliveryNote?: string;
  deliveryFeeUuid?: string;
}

const DEFAULT_STATE: SelfServiceStateModel = {
  isSetupComplete: false,
  isMenuLoaded: false,
  isMacroGridLoaded: false,
  isMenuWSConnected: false,
  addingDinerToTicket: false,
  focusedCategory: null,
  focusedMenuItem: null
};

@State<SelfServiceStateModel>({
  name: 'self_service',
  defaults: DEFAULT_STATE
})
@Injectable()
export class SelfServiceState {
  constructor(
    private dinerService: DinerService,
    private store: Store,
    private baseProvider: BaseProvider,
    private router: Router,
    private webSocketProvider: WebSocketProvider,
    private modalService: ModalService,
    private actions$: Actions,
    private ticketService: TicketService
  ) {}

  @Action(Initialize)
  initialize(ctx: StateContext<SelfServiceStateModel>) {
    const state = ctx.getState();

    //Retrieve current meal ID stored on the state
    const { currentMealId: current_meal_id, location } =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN);

    const stateMealId: number = LocationStateHelper.getMealId(
      current_meal_id,
      location.type
    );

    //Calculate the current meal ID based on the current time
    const mealEndTimes =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location?.meal_end_times;
    const currentMealId = MealHelper.getMealIdByTime(mealEndTimes);

    // If the menu or macro grid have been marked as not loaded (i.e. menu change event from WS or app starting) OR
    // the meal ID has changed, refetch the menu and macro grid
    if (
      !state.isMenuLoaded ||
      !state.isMacroGridLoaded ||
      stateMealId !== currentMealId
    ) {
      // If we are reloading the menu because the meal ID has changed, mark the menu and macro grid as not loaded
      // so the loader will be shown on the Self Service Setup page
      if (stateMealId !== currentMealId) {
        ctx.patchState({ isMacroGridLoaded: false, isMenuLoaded: false });
      }

      ctx.dispatch([
        new StateReset(MenuState),
        new UpdateSelectedMealId(currentMealId, this.formatDate(new Date()))
      ]);
    }

    if (!state.isMenuWSConnected) {
      const facilityId =
        this.store.selectSnapshot(APP_STATE_TOKEN).device.facility_id;
      this.webSocketProvider.subscribeTo(
        CommonConstant.WEBSOCKET_CHANNELS.MENU_CHANNEL,
        { facility_id: facilityId },
        (message: any) => {
          // If the menu changes, reset the menu and macro grid loaded flags so they will be reloaded
          // the next time the user navigates to the Self Service Setup page or User or the user is locating at Setup page
          if (message && message.event === 'menu_change') {
            ctx.dispatch(new MenuChangeEventFromWs());
          }
        }
      );
      ctx.patchState({ isMenuWSConnected: true });
    }
  }

  @Action(MenuChangeEventFromWs)
  menuChangeEventFromWs(ctx: StateContext<SelfServiceStateModel>) {
    ctx.patchState({ isMenuLoaded: false, isMacroGridLoaded: false });
  }

  @Action(GetMacroGridDetailSuccess)
  getMacroGridDetailSuccess(ctx: StateContext<SelfServiceStateModel>) {
    const locationType =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location.type;
    // Temporary patch to fix issue where this is getting triggered in Dining Rooms or Quick Service
    // TODO: Look into how to initialize Self Service state only when in Self Service - NGXS LazyLoading states ForFeature
    if (locationType === LocationType.SelfServe) {
      ctx.patchState({ isMacroGridLoaded: true });
    }
  }

  @Action(GetMenuSuccess)
  getMenuSuccess(ctx: StateContext<SelfServiceStateModel>) {
    const locationType =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location.type;
    // Temporary patch to fix issue where this is getting triggered in Dining Rooms or Quick Service
    // TODO: Look into how to initialize Self Service state only when in Self Service - NGXS LazyLoading states ForFeature
    if (locationType === LocationType.SelfServe) {
      ctx.patchState({ isMenuLoaded: true });
    }
  }

  //When completing the Self Service Setup, start the idle timer and navigate to the Self Service Ordering page
  @Action(CreateTicketSuccess)
  completeSelfServiceSetup(ctx: StateContext<SelfServiceStateModel>) {
    const location: PosLocation =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location;
    const idleSetup: boolean =
      this.store.selectSnapshot(APP_STATE_TOKEN).idleSetup;

    // Temporary patch to fix issue where this is getting triggered in Dining Rooms or Quick Service
    // TODO: Look into how to initialize Self Service state only when in Self Service - NGXS LazyLoading states ForFeature
    if (location.type === LocationType.SelfServe) {
      ctx.patchState({ isSetupComplete: true });
      if (!idleSetup && environment.MSPOS_IS_DEPLOYED_BUILD) {
        ctx.dispatch(new StartIdleTime());
      }

      this.modalService.closeLoadingModal();
    }
  }

  @Action(SskGetDinerByBarcode)
  getDinerByBarcode(
    ctx: StateContext<SelfServiceStateModel>,
    { barcode, dinerType }: SskGetDinerByBarcode
  ) {
    return this.dinerService
      .getDinerByBarcode(barcode, 'Searching for diner...')
      .pipe(
        map((diners) =>
          (diners || []).filter((diner) => diner.type === dinerType)
        ),
        switchMap((diners) => {
          if (diners.length === 0 || diners.length > 1) {
            const errorKey =
              diners.length > 1
                ? ErrorCodeKey.MultipleDiners
                : ErrorCodeKey.NotFoundDiner;
            return ctx.dispatch(new SskGetDinerByBarcodeFailed(errorKey));
          }

          ctx.patchState({ addingDinerToTicket: true });
          return ctx.dispatch(new AddDinerToTicket(diners[0]));
        }),
        catchError(() =>
          ctx.dispatch(
            new GetDinerByBarcodeError(
              `There was a problem getting the diner information.`
            )
          )
        )
      );
  }

  @Action([StartOver, CloseTicketSuccess, CancelOrder])
  startOver(
    ctx: StateContext<SelfServiceStateModel>,
    payload: StartOver | CancelOrder | CloseTicketSuccess
  ) {
    ctx.patchState({ isSetupComplete: false, addingDinerToTicket: false });

    const actions = [new StopIdle()];

    if (payload instanceof CancelOrder || payload instanceof StartOver) {
      const ticketState = this.store.selectSnapshot(TICKETS_STATE_TOKEN),
        selectedTicketId = ticketState.selectedTicket.data,
        ticketsById = ticketState.tickets.byId,
        selectedTicket = selectedTicketId && ticketsById[selectedTicketId];

      const cancelTicketHandler = () => {
        if (selectedTicket.can_be_cancelled) {
          actions.push(new CancelTicket());
        } else if (
          [TicketStatus.PARTIAL_PAID, TicketStatus.PAID].includes(
            selectedTicket.status
          )
        ) {
          this.baseProvider.popupAlert(
            'Warning',
            `Void of ticket cannot be completed. <br>Payment has already been made on this ticket`
          );
        } else {
          const default_operator_id =
            this.store.selectSnapshot(LOCATION_STATE_TOKEN).location
              .self_serve_settings.default_operator_id;
          actions.push(
            new VoidTicket(default_operator_id, default_operator_id, '')
          );
        }
      };

      if (selectedTicket) {
        if (payload instanceof CancelOrder) {
          cancelTicketHandler();
        } else if (payload instanceof StartOver && payload.isCancelTicket) {
          if (
            selectedTicket.outstanding_balance <= 0 &&
            selectedTicket.ticket_items?.length > 0 &&
            selectedTicket.status !== TicketStatus.CLOSED
          ) {
            // DEF-4398 SelfService - If Idle on Payment Complete Screen, Ticket is Deleted
            // the ticket must be closed instead of deleted
            const transactions = this.store.selectSnapshot(
              TRANSACTIONS_STATE_TOKEN
            ).transactions;

            const paymentTypes = (selectedTicket.transactions || []).map(
              (id) => transactions.byId[id].type
            );

            const selfServeSettings: SelfServeSettings =
              this.store.selectSnapshot(LOCATION_STATE_TOKEN).location
                .self_serve_settings;

            if (selfServeSettings?.fire_order_on_payment) {
              // fire ticket and close
              actions.push(
                new FireTicketAndClose(
                  selectedTicket.device_ticket_uuid,
                  paymentTypes
                )
              );
            } else {
              // close ticket
              actions.push(
                new CloseTicket(selectedTicket.device_ticket_uuid, paymentTypes)
              );
            }
          } else {
            cancelTicketHandler();
          }
        }
      }
    }

    return ctx.dispatch(actions).pipe(
      tap(() => {
        //Reset all states except for the AppState, LocationState, SelfServiceState, and MenuState
        ctx.dispatch([
          new StateOverwrite([
            TicketsState,
            {
              ...DEFAULT_TICKET_STATE,
              lastTicketTransaction:
                this.store.selectSnapshot(TICKETS_STATE_TOKEN)
                  .lastTicketTransaction
            }
          ]),
          new StateResetAll(
            HardwareDeviceState,
            AppState,
            LocationState,
            SelfServiceState,
            MenuState,
            TicketsState
          )
        ]);

        this.modalService.closeLoadingModal();

        //Navigate to the Self Service Setup page
        if (
          payload instanceof CancelOrder ||
          payload instanceof CloseTicketSuccess ||
          payload.redirectToSetup
        ) {
          this.router.navigateByUrl(PageConstant.SELF_SERVICE_SETUP_PAGE, {
            replaceUrl: true
          });
        } else {
          // If we are not navigating to the Self Service Setup page, it means we are already there.
          // Reinitialize the Self Service page
          ctx.dispatch(new Initialize());
        }
      })
    );
  }

  @Action(ResetSelfServiceState)
  resetSelfService({ setState }: StateContext<SelfServiceStateModel>) {
    //Unsubscribe from menu channel when resetting self service state
    this.webSocketProvider.unsubscribe(
      CommonConstant.WEBSOCKET_CHANNELS.MENU_CHANNEL
    );
    this.modalService.closeAllModals();
    setState(DEFAULT_STATE);
  }

  @Action(FocusOnCategoryInCategoryList)
  focusOnCategoryInCategoryList(
    { patchState }: StateContext<SelfServiceStateModel>,
    { category }: FocusOnCategoryInCategoryList
  ) {
    patchState({ focusedCategory: category });
  }

  private formatDate(date: number | Date, formatString: string = 'yyyy-MM-dd') {
    return format(date, formatString);
  }

  @Action(FocusOnMenuItem)
  focusOnMenuItem(
    { dispatch, patchState }: StateContext<SelfServiceStateModel>,
    { menuItem }: FocusOnMenuItem
  ) {
    const isInCategoryView =
      this.store.selectSnapshot(MENU_STATE_TOKEN).macro_grid_view ===
      MacroGridView.CATEGORY;

    return dispatch(new ViewAllItems()).pipe(
      switchMap(() =>
        (isInCategoryView
          ? this.actions$.pipe(ofActionCompleted(ResetFocusState))
          : of(void 0)
        ).pipe(
          take(1),
          tap(() => patchState({ focusedMenuItem: menuItem }))
        )
      )
    );
  }

  @Action(ResetFocusState)
  resetFocusState({ patchState }: StateContext<SelfServiceStateModel>) {
    patchState({ focusedMenuItem: null, focusedCategory: null });
  }

  @Action(SelectOrderType)
  selectOrderType(
    ctx: StateContext<SelfServiceStateModel>,
    { orderType, delivery_note }: SelectOrderType
  ) {
    const delivery_type: DeliveryType =
      SskHelper.SskOrderTypeMapper[orderType].deliveryType;
    const device_ticket_uuid =
      this.store.selectSnapshot(TICKETS_STATE_TOKEN).selectedTicket.data;
    const add_delivery_fee =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location
        .self_serve_settings.add_delivery_fee;

    if (delivery_type === DeliveryType.DELIVERY && add_delivery_fee) {
      ctx.dispatch(new AddDeliveryFeeToTicket());
    }

    return this.ticketService
      .updateTicket({
        device_ticket_uuid,
        delivery_type,
        delivery_note
      })
      .pipe(tap((ticket) => ctx.dispatch(new UpdateTicketSuccess(ticket))));
  }

  @Action(SetDeliveryNote)
  setDeliveryNote(
    ctx: StateContext<SelfServiceStateModel>,
    { deliveryNote: delivery_note }: SetDeliveryNote
  ) {
    const device_ticket_uuid =
      this.store.selectSnapshot(TICKETS_STATE_TOKEN).selectedTicket.data;

    return this.ticketService
      .updateTicket({
        device_ticket_uuid,
        delivery_note
      })
      .pipe(tap((ticket) => ctx.dispatch(new UpdateTicketSuccess(ticket))));
  }

  @Action(AddDeliveryFeeToTicket)
  addDeliveryFeeToTicket(ctx: StateContext<SelfServiceStateModel>) {
    const {
      add_delivery_fee,
      delivery_product_id,
      delivery_product_name,
      delivery_fee_amount
    } =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location
        .self_serve_settings;

    if (add_delivery_fee && delivery_product_id) {
      const request = TicketHelper.generateDeliveryTicketItem(
        delivery_product_name,
        delivery_product_id,
        delivery_fee_amount || 0
      );

      ctx.dispatch(new AddTicketItem(request)).subscribe(() => {
        ctx.patchState({
          deliveryFeeUuid: request.device_ticket_item_uuid
        });
      });
    }
  }

  @Action(RemoveDeliveryFeeFromTicket)
  removeDeliveryFeeFromTicket(ctx: StateContext<SelfServiceStateModel>) {
    const { deliveryFeeUuid } = ctx.getState();
    if (deliveryFeeUuid) {
      return this.ticketService.cancelTicketItem(deliveryFeeUuid).pipe(
        tap((ticket) => {
          ctx.dispatch([
            new UpdateTicketSuccess(ticket),
            new RemoveTicketItemSuccess(ticket, deliveryFeeUuid)
          ]);

          ctx.patchState({
            deliveryFeeUuid: null
          });
        })
      );
    }
  }
}
