import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { produce, Draft } from 'immer';
import _ from 'lodash';

import {
  LocationType,
  NormalizedObject,
  NormalizedSeat,
  TableDetailSeat
} from 'src/app/models';
import {
  SelectNewSeat,
  SelectSeat,
  SelectSeatFromDiagram,
  SelectSeatFromDiagramSuccess,
  UnselectSeat,
  UnselectSelectedSeat,
  UpdateLocalSeat
} from './seats.action';
import {
  AddDinerToTicketSuccess,
  ChangeTicketMealSuccess,
  CloseTicketWsEvent,
  CreateTicket,
  CreateTicketForSeatSuccess,
  RemoveDinerFromTicketSuccess,
  RemoveTicketSuccess,
  SelectTicket,
  TemporarySeatMealChangeSuccess,
  TicketLockedChangedFromWS
} from '../tickets/tickets.action';
import {
  AddTemporarySeatSuccess,
  GetTablesSuccess,
  GetTableSuccess,
  RemoveTemporarySeat,
  SelectASeat,
  SelectASeatSuccess,
  SelectNewTable,
  UnSelectTable,
  UpdateTableStatus
} from '../tables/tables.action';
import { TicketService } from 'src/app/services';
import { EMPTY, Observable, forkJoin, switchMap, tap } from 'rxjs';
import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';
import { NormalizrHelper } from 'src/app/helpers';
import { TableProvider } from 'src/app/providers';
import { LOCATION_STATE_TOKEN } from 'src/app/store/location/location.state';

export interface SeatsStateModel {
  seats: NormalizedObject<NormalizedSeat, number>;
  selectedSeat: number;
}

export const SEATS_STATE_TOKEN = new StateToken<SeatsStateModel>('seats');

@State({
  name: SEATS_STATE_TOKEN,
  defaults: {
    seats: {
      byId: null,
      allIds: []
    },
    selectedSeat: null
  }
})
@Injectable()
export class SeatsState {
  constructor(
    private readonly store: Store,
    private readonly ticketService: TicketService,
    private readonly tableProvider: TableProvider
  ) {}

  @Action(UnselectSelectedSeat)
  unselectSelectedSeat(ctx: StateContext<SeatsStateModel>) {
    ctx.setState(
      produce((draft: SeatsStateModel) => {
        const seat = draft.selectedSeat;
        if (!draft.seats.byId || !seat) {
          return;
        }

        draft.selectedSeat = null;

        // if (draft.seats.byId[seat]) {
        //   draft.seats.byId[seat].local_attributes.selected = false;
        // }
      })
    );
  }

  @Action([GetTablesSuccess, GetTableSuccess])
  getTablesSuccess(
    ctx: StateContext<SeatsStateModel>,
    payload: GetTablesSuccess | GetTableSuccess
  ) {
    let normalizedSeats = NormalizrHelper.normalizeTables(payload.tables)
      .entities.seats;

    ctx.setState(
      produce((draft: SeatsStateModel) => {
        if (normalizedSeats) {
          if (draft.seats.byId) {
            normalizedSeats = this.updateSeatProp(
              normalizedSeats,
              draft.seats.byId
            );
          }

          draft.seats = {
            byId: { ...draft.seats.byId, ...normalizedSeats },
            allIds: _.union(
              draft.seats.allIds,
              Object.keys(normalizedSeats).map((key) => +key)
            )
          };
        }
      })
    );
  }

  @Action(GetTableSuccess)
  updateSeatAfterGetTableSuccess(
    ctx: StateContext<SeatsStateModel>,
    payload: GetTableSuccess
  ) {
    if (!payload.table.seats) {
      return;
    }

    ctx.setState(
      produce((draft: SeatsStateModel) => {
        payload.table.seats.forEach((seat: TableDetailSeat) => {
          if (!!seat.ticket) {
            draft.seats.byId[seat.id].ticket_locked = seat.ticket.locked;
            draft.seats.byId[seat.id].ticket_locked_by_operator_id =
              seat.ticket.locked_by_operator_id;
          }
        });
      })
    );
  }

  @Action(SelectASeat)
  selectASeat(ctx: StateContext<SeatsStateModel>, payload: SelectASeat) {
    return ctx
      .dispatch(new SelectSeat(payload.seatId))
      .pipe(tap(() => ctx.dispatch(new SelectASeatSuccess())));
  }

  @Action(SelectSeat)
  selectSeat(
    ctx: StateContext<SeatsStateModel>,
    { seatId, removeTicket }: SelectSeat
  ) {
    let state = ctx.getState();
    if (!state.seats.byId) {
      return;
    }

    const selectedSeat =
      state.seats.byId && state.seats.byId[seatId || state.selectedSeat];
    if (removeTicket && selectedSeat && selectedSeat.is_temporary_seat) {
      ctx.dispatch(new RemoveTemporarySeat(selectedSeat.tableId, seatId));
    }

    ctx.setState(
      produce((draft: SeatsStateModel) => {
        const seat = seatId || draft.selectedSeat;
        if (!draft.seats.byId) {
          return;
        }

        //If seat is temporary seat and is removing ticket, remove the temporary seat
        if (selectedSeat && selectedSeat.is_temporary_seat && removeTicket) {
          delete draft.seats.byId[seat];
          _.remove(draft.seats.allIds, (item) => item === seat);
          draft.selectedSeat = null;
          return;
        }

        //If removing a Ticket, set seat ticket attributes to null
        if (removeTicket && seat && draft.seats.byId[seat]) {
          this._setRemovedSeatToNull(draft.seats.byId[seat]);
        }

        //If removing the selected Ticket OR selecting a new Seat, update the selectedSeat value
        //This will not get triggered when the removeTicket event comes from a WS action.
        if (
          (!removeTicket && !seatId) ||
          (removeTicket && !seatId) ||
          (seatId && !removeTicket)
        ) {
          draft.selectedSeat = seatId;
        }
      })
    );

    //Select the seat assigned ticket if present
    if (seatId && !removeTicket) {
      state = ctx.getState();
      const seatById = state.seats.byId[seatId];
      const ticketId = seatById.device_ticket_uuid || seatById.ticket;
      if (ticketId) {
        return ctx.dispatch(new SelectTicket(ticketId));
      } else {
        ctx.dispatch(new CreateTicket());
      }
    } else if (!seatId) {
      return ctx.dispatch(new SelectTicket());
    }
  }

  @Action(ChangeTicketMealSuccess)
  changeTicketMealSuccess(
    ctx: StateContext<SeatsStateModel>,
    payload: ChangeTicketMealSuccess
  ) {
    this.updateLocalSeatHandler(ctx, {
      id: payload.newTicket.seat_id,
      ticket: payload.newTicket.device_ticket_uuid,
      device_ticket_uuid: payload.newTicket.device_ticket_uuid,
      ticket_created_at: payload.newTicket.created_at,
      ticket_status: payload.newTicket.status,
      diner_id: payload.newTicket.diner_id
    });
  }

  @Action(AddTemporarySeatSuccess)
  addTemporarySeatSuccess(
    ctx: StateContext<SeatsStateModel>,
    { seat }: AddTemporarySeatSuccess
  ) {
    const normalizedSeats = NormalizrHelper.normalizeSeats([seat]).entities
      .seats;
    ctx.setState(
      produce((draft: SeatsStateModel) => {
        draft.selectedSeat = seat.id;
        if (normalizedSeats) {
          draft.seats = {
            byId: _.merge(draft.seats.byId, normalizedSeats),
            allIds: _.union(
              draft.seats.allIds,
              Object.keys(normalizedSeats).map((key) => +key)
            )
          };
        }
      })
    );
    ctx.dispatch(new CreateTicket());
  }

  @Action(UpdateLocalSeat)
  updateLocalSeat(
    ctx: StateContext<SeatsStateModel>,
    { normalizedSeat }: UpdateLocalSeat
  ) {
    this.updateLocalSeatHandler(ctx, normalizedSeat);
  }

  private updateLocalSeatHandler(
    ctx: StateContext<SeatsStateModel>,
    normalizedSeat: Partial<NormalizedSeat>
  ) {
    const locationType =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location.type;
    const isDiningRoom: boolean = locationType === LocationType.DiningRoom;

    if (!isDiningRoom) {
      return;
    }

    ctx.setState(
      produce((draft: SeatsStateModel) => {
        const seat_id = normalizedSeat.id || draft.selectedSeat;
        normalizedSeat.id = seat_id;
        if (draft.seats.byId && draft.seats.byId[seat_id] && normalizedSeat) {
          const updateTableStatus =
            draft.seats.byId[seat_id].floorplan_status !==
              normalizedSeat.floorplan_status ||
            draft.seats.byId[seat_id].ticket_status !==
              normalizedSeat.ticket_status;
          draft.seats.byId[seat_id] = _.extend(
            draft.seats.byId[seat_id],
            normalizedSeat
          );

          if (updateTableStatus && normalizedSeat.tableId) {
            ctx.dispatch(new UpdateTableStatus(normalizedSeat.tableId));
          }
        }
      })
    );
  }

  @Action(SelectNewSeat)
  selectNewSeat(ctx: StateContext<SeatsStateModel>, payload: SelectNewSeat) {
    const operatorId = this.store.selectSnapshot(APP_STATE_TOKEN).operator.id;
    const prevSeatId = ctx.getState().selectedSeat;
    const seats = ctx.getState().seats;
    const seatId = payload.seatId;
    const ticketUuid = seats.byId[seatId].device_ticket_uuid;
    const prevSeat = seats.byId[prevSeatId];
    const actions$: Observable<any>[] = [];

    if (!!prevSeatId) {
      ctx.setState(
        produce((draft: SeatsStateModel) => {
          draft.seats.byId[prevSeatId].ticket_locked = false;
          draft.seats.byId[prevSeatId].ticket_locked_by_operator_id = null;
        })
      );

      actions$.push(
        this.ticketService.unlockTicket(prevSeat.device_ticket_uuid)
      );
    }

    if (ticketUuid) {
      actions$.push(this.ticketService.lockTicket(ticketUuid, operatorId));
    }

    ctx.setState(
      produce((draft: SeatsStateModel) => {
        draft.selectedSeat = seatId;
        draft.seats.byId[draft.selectedSeat].ticket_locked = true;
        draft.seats.byId[draft.selectedSeat].ticket_locked_by_operator_id =
          operatorId;
      })
    );

    forkJoin(actions$).subscribe();
  }

  @Action(SelectSeatFromDiagram)
  selectSeatFromDiagram(
    ctx: StateContext<SeatsStateModel>,
    payload: SelectSeatFromDiagram
  ) {
    return ctx.dispatch(new SelectNewTable(payload.tableId)).pipe(
      switchMap(() => {
        const state = ctx.getState();
        const selectedSeat =
          state.seats.byId && state.seats.byId[payload.seatId];
        if (selectedSeat.ticket_locked) {
          this.tableProvider.showTicketLockedMsg(
            selectedSeat.ticket_locked_by_operator_id
          );
          return EMPTY;
        }

        return ctx.dispatch(new SelectNewSeat(payload.seatId));
      }),
      switchMap(() => ctx.dispatch(new SelectSeatFromDiagramSuccess()))
    );
  }

  @Action(UnselectSeat)
  unselectSeat(ctx: StateContext<SeatsStateModel>) {
    const prevSeatId = ctx.getState().selectedSeat;

    if (!prevSeatId) {
      return;
    }

    ctx.setState(
      produce((draft: SeatsStateModel) => {
        draft.selectedSeat = null;
        draft.seats.byId[prevSeatId].ticket_locked = false;
        draft.seats.byId[prevSeatId].ticket_locked_by_operator_id = null;
      })
    );
  }

  @Action(CreateTicketForSeatSuccess)
  createTicketForSeatSuccess(
    ctx: StateContext<SeatsStateModel>,
    payload: CreateTicketForSeatSuccess
  ) {
    ctx.setState(
      produce((draft: SeatsStateModel) => {
        draft.seats.byId[payload.ticket.seat_id].ticket_created_at =
          payload.ticket.created_at;
        draft.seats.byId[payload.ticket.seat_id].ticket =
          payload.ticket.device_ticket_uuid;
        draft.seats.byId[payload.ticket.seat_id].device_ticket_uuid =
          payload.ticket.device_ticket_uuid;
      })
    );
  }

  private updateSeatProp(
    normalizedSeats: _.NumericDictionary<NormalizedSeat>,
    byId: _.NumericDictionary<NormalizedSeat>
  ): _.NumericDictionary<NormalizedSeat> {
    return _.reduce(
      normalizedSeats,
      (result, seat) => {
        result[seat.id] = { ...byId[seat.id], ...seat };
        return result;
      },
      {}
    );
  }

  @Action(TemporarySeatMealChangeSuccess)
  temporarySeatMealChangeSuccess(
    ctx: StateContext<SeatsStateModel>,
    payload: TemporarySeatMealChangeSuccess
  ) {
    ctx.setState(
      produce((draft: SeatsStateModel) => {
        const normalizedSeats = NormalizrHelper.normalizeSeats([
          {
            ...payload.seat,
            // DEF-5695 Missing device_ticket_uuid and tableId, thus, not able to update the seat in table checkout view
            device_ticket_uuid: payload.ticket.device_ticket_uuid,
            tableId: payload.ticket.table_id
          }
        ]).entities.seats;
        draft.seats.allIds.splice(
          draft.seats.allIds.indexOf(draft.selectedSeat),
          1,
          payload.seat.id
        );
        delete draft.seats.byId[draft.selectedSeat];
        draft.seats.byId = {
          ...draft.seats.byId,
          ...normalizedSeats
        };
        draft.selectedSeat = payload.seat.id;
        draft.seats.byId[draft.selectedSeat].device_ticket_uuid =
          payload.ticket.device_ticket_uuid;
      })
    );
  }

  private _setRemovedDinerFromSeat(seat: Draft<NormalizedSeat>) {
    seat.diner_id = null;
    seat.diner_name = null;
    seat.first_name = null;
    seat.last_name = null;
  }

  private _setRemovedSeatToNull(seat: Draft<NormalizedSeat>) {
    this._setRemovedDinerFromSeat(seat);
    seat.device_ticket_uuid = null;
    seat.ticket_locked = false;
    seat.ticket_locked_by_operator_id = null;
    seat.ticket_status = null;
  }

  @Action(CloseTicketWsEvent)
  closeTicketWsEvent(
    ctx: StateContext<SeatsStateModel>,
    { wsData }: CloseTicketWsEvent
  ) {
    ctx.setState(
      produce((draft) => {
        const seatsById = draft.seats.byId;
        const removedTicketSeat = seatsById && seatsById[wsData.seat_id];
        if (removedTicketSeat) {
          this._setRemovedSeatToNull(removedTicketSeat);
        }
      })
    );
  }

  @Action(TicketLockedChangedFromWS)
  ticketLockedChangedFromWS(
    ctx: StateContext<SeatsStateModel>,
    payload: TicketLockedChangedFromWS
  ) {
    const state = ctx.getState();
    if (
      !payload.data.seat_id ||
      !state.seats.byId ||
      !state.seats.byId[payload.data.seat_id]
    ) {
      return;
    }

    ctx.setState(
      produce((draft: SeatsStateModel) => {
        draft.seats.byId[payload.data.seat_id].ticket_locked =
          payload.data.locked;
        draft.seats.byId[payload.data.seat_id].ticket_locked_by_operator_id =
          payload.data.locked_by_operator_id;
      })
    );
  }

  @Action(RemoveTicketSuccess)
  removeTicketSuccess(
    { setState }: StateContext<SeatsStateModel>,
    { device_ticket_uuid }: RemoveTicketSuccess
  ) {
    setState(
      produce((draft: SeatsStateModel) => {
        const seatsById = draft.seats.byId;
        const removedTicketSeat = seatsById && seatsById[device_ticket_uuid];
        if (removedTicketSeat) {
          this._setRemovedSeatToNull(removedTicketSeat);
        }
      })
    );
  }

  @Action(AddDinerToTicketSuccess)
  addDinerToTicketSuccess(
    ctx: StateContext<SeatsStateModel>,
    payload: AddDinerToTicketSuccess
  ) {
    if (!payload.seatId) {
      return;
    }
    ctx.setState(
      produce((draft: SeatsStateModel) => {
        draft.seats.byId[payload.seatId].diner_name =
          payload.diner.name ||
          `${payload.diner.first_name} ${payload.diner.last_name}`;
        draft.seats.byId[payload.seatId].first_name = payload.diner.first_name;
        draft.seats.byId[payload.seatId].last_name = payload.diner.last_name;
      })
    );
  }

  @Action(RemoveDinerFromTicketSuccess)
  removeDinerFromTicketSuccess(ctx: StateContext<SeatsStateModel>) {
    ctx.setState(
      produce((draft: SeatsStateModel) => {
        const seat = draft.selectedSeat && draft.seats.byId[draft.selectedSeat];
        if (seat) {
          this._setRemovedDinerFromSeat(seat);
        }
      })
    );
  }

  @Action(UnSelectTable)
  unSelectSeatWhenTableUnselected(ctx: StateContext<SeatsStateModel>) {
    if (ctx.getState().selectedSeat) {
      ctx.dispatch(new UnselectSeat());
    }
  }
}
