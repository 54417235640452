import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBurgerSoda, faUtensils } from '@fortawesome/pro-solid-svg-icons';
import { isAfter } from 'date-fns';
import { DeliveryType, GiftCard } from 'src/app/models';
import { SskOrderType } from 'src/app/store/self-service/self-service.selector';

export class SskHelper {
  static checkGiftCardValid(giftCard: GiftCard): string {
    let errorMessage: string;

    if (
      giftCard.expiry_date &&
      !isAfter(new Date(giftCard.expiry_date), new Date())
    ) {
      errorMessage = 'The gift card cannot be used at this time.';
    } else if (+giftCard.balance <= 0) {
      errorMessage =
        'The gift card entered does not have a balance and cannot be used at this time.';
    } else if (Object.keys(giftCard).length === 0) {
      //DEF-4067: if giftcard is invalid an empty object is returned i.e. {}
      errorMessage =
        'Gift Card not found. Please try again or select a different payment method';
    }

    return errorMessage;
  }

  static SskDeliveryTypeMapper: Record<
    DeliveryType,
    Partial<{
      orderType: SskOrderType;
    }>
  > = {
    [DeliveryType.DELIVERY]: {
      orderType: 'delivery'
    },
    [DeliveryType.TO_GO]: {
      orderType: 'takeout'
    },
    [DeliveryType.TO_TABLE]: {
      orderType: 'dinein'
    },
    [DeliveryType.PICK_UP]: {},
    [DeliveryType.TO_SEAT]: {},
    [DeliveryType.TO_ROOM]: {}
  };

  static SskOrderTypeMapper: Record<
    SskOrderType,
    {
      label: string;
      icon?: IconDefinition;
      src?: string;
      deliveryType: DeliveryType;
    }
  > = {
    delivery: {
      label: 'Delivery',
      src: 'assets/icon/self-service/moped.svg',
      deliveryType: DeliveryType.DELIVERY
    },
    dinein: {
      label: 'Dine-In',
      icon: faUtensils,
      deliveryType: DeliveryType.TO_TABLE
    },
    takeout: {
      label: 'Takeout',
      icon: faBurgerSoda,
      deliveryType: DeliveryType.TO_GO
    }
  };

  static AllOrderTypes: SskOrderType[] = ['dinein', 'takeout', 'delivery'];
}
