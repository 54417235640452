import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
//----------------------------------------------
import { ChargeGuestMealModal } from './charge-guest-meal.modal';

@NgModule({
  declarations: [ChargeGuestMealModal],
  imports: [CommonModule, IonicModule]
})
export class ChargeGuestMealModalModule {}
