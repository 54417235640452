import { Injectable } from '@angular/core';
import { Plugin, registerPlugin } from '@capacitor/core';
import { Store } from '@ngxs/store';
import { HardwareDeviceSelectors } from 'src/app/store/hardware-device/hardware-device.selector';

export interface NFCReaderPlugin extends Plugin {
  startNFC(): Promise<{ value: string }>;

  stopNFC(): Promise<{ value: string }>;

  isNFCEnabled(): Promise<{ success: boolean }>;
}

interface NFCDataI {
  data: string;
}

@Injectable({
  providedIn: 'root'
})
export class NfcReaderService {
  private NFCReader: NFCReaderPlugin;

  constructor(private readonly _store: Store) {
    this.NFCReader = registerPlugin<NFCReaderPlugin>('CloudStormNFC');
  }

  async startNFC(callback: (payload: NFCDataI) => void) {
    if (this._isAndroid && this._isNfcEnabled) {
      await this.NFCReader.startNFC();
      await this.NFCReader.addListener('NFCReaderData', callback);
    }
  }

  async stopNFC() {
    if (this._isAndroid && this._isNfcEnabled) {
      await this.NFCReader.removeAllListeners();
      await this.NFCReader.stopNFC();
    }
  }

  async isNFCEnabled(): Promise<{ success: boolean }> {
    return await this.NFCReader.isNFCEnabled();
  }

  private get _isAndroid() {
    return this._store.selectSnapshot(HardwareDeviceSelectors.isAndroid);
  }

  private get _isNfcEnabled() {
    return this._store.selectSnapshot(HardwareDeviceSelectors.nfcEnabled);
  }
}
