import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot
} from '@angular/router';
import { Actions, Store } from '@ngxs/store';
//-----------------------------------------------------------------------------------
import { PageConstant } from 'src/app/constants';
import { UnselectSeat } from 'src/app/store/seats/seats.action';
import { UpdateTakeoutDeliveryFlag } from 'src/app/store/takeout-delivery/takeout-delivery.action';

@Injectable({
  providedIn: 'root'
})
export class LeaveTakeoutDeliveryGuard implements CanDeactivate<boolean> {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store
  ) {}

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) {
    const isNextPaymentPage = nextState.url.includes(
      PageConstant.PAYMENTS_PAGE
    );
    const isInProgressTicket = currentState.url.includes('new');

    if (!isNextPaymentPage && !isInProgressTicket) {
      this.store.dispatch([
        new UpdateTakeoutDeliveryFlag(false),
        new UnselectSeat()
      ]);
    }

    return true;
  }
}
