/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SelectResidentForGuestBillingModal } from './select-resident-for-guest-billing.modal';
import { DebounceClickDirectiveModule } from '../../directives/debounce-click/debounce-click-directive.module';
import { AvatarControlModule } from '../../components/shared/avatar-control/avatar-control.module';
import { BlurButtonDirectiveModule } from '../../directives/blur-button/blur-button-directive.module';
import { DinerAssignmentSkeletonComponentModule } from '../../components/shared/skeleton/diner-assignment-skeleton/diner-assignment-skeleton/diner-assignment-skeleton-component.module';
import { InputFocusDirectiveModule } from '../../directives/input-focus-directive/input-focus-directive.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [SelectResidentForGuestBillingModal],
  imports: [
    CommonModule,
    IonicModule,
    DebounceClickDirectiveModule,
    AvatarControlModule,
    BlurButtonDirectiveModule,
    DinerAssignmentSkeletonComponentModule,
    InputFocusDirectiveModule
  ]
})
export class SelectResidentForGuestBillingModule {}
