import { PosTenantType } from 'src/app/constants';
import { environment } from 'src/environments/environment';
import { PosEnv } from 'src/environments/environment.model';

export class AppUtil {
  /** API URL Generator */
  static configHost(tenant: PosTenantType = PosTenantType.SYNERGY): string {
    const env = AppUtil.getEnv();
    const subdomain = AppUtil.getTenantSubdomain(tenant);

    //Use when deploying
    return `https://${subdomain}${env}.touch.mealsuite.com`;
  }

  private static getTenantSubdomain(
    tenant: PosTenantType = PosTenantType.SYNERGY
  ): string {
    return (tenant || 'synergy').replace('.live', '');
  }

  /** Get the environment */
  private static getEnv(
    env: PosEnv = environment.MSPOS_ENVIRONMENT as PosEnv
  ): string {
    if (env === 'production') {
      return '';
    }
    return '.' + env;
  }
}
