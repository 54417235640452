import { createPropertySelectors, createSelector } from '@ngxs/store';

import { DinersStateModel, DinersState } from './diners.state';
import { TicketsStateSelectors } from 'src/app/store/tickets/tickets.selectors';
import { DinerHelper } from 'src/app/helpers/diner.helper';
import { DinerType } from 'src/app/models';

export class DinersSelectors {
  static slices = createPropertySelectors<DinersStateModel>(DinersState);

  static getDiners = createSelector(
    [DinersSelectors.slices.diners],
    (diners) => diners
  );

  static dinersById = createSelector(
    [DinersSelectors.getDiners],
    (diners) => diners.byId
  );

  static filteredDiners = createSelector(
    [DinersSelectors.slices.filteredDiners],
    (filteredDiners) => filteredDiners
  );

  static assignedResidents = createSelector(
    [DinersSelectors.slices.assignedResidents],
    (assignedResidents) => assignedResidents
  );

  static getSelectedDinerId = createSelector(
    [TicketsStateSelectors.selectedTicketId, TicketsStateSelectors.ticketsById],
    (selectedTicketId, ticketById) =>
      ticketById && selectedTicketId && ticketById[selectedTicketId].diner_id
  );

  static getSelectedDiner = createSelector(
    [DinersSelectors.dinersById, DinersSelectors.getSelectedDinerId],
    (dinerById, selectedDinerId) =>
      DinerHelper.getTicketDiner(dinerById, selectedDinerId)
  );

  // move from diners.selector.ts
  static getFilteredResidents = createSelector(
    [DinersSelectors.filteredDiners],
    (filteredDiners) =>
      filteredDiners.filter((r) => r.type === DinerType.Resident)
  );

  // move from diners.selector.ts
  static getDinerById = createSelector(
    [DinersSelectors.dinersById],
    (dinersById) => (id: number) => dinersById && dinersById[id]
  );
}
